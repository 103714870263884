.m-blank{
    &-XS{
        padding: 17px 0 0;
        display: block;
    }
    &-S{
        padding: 30px 0 0;
        display: block;
        @include sp() {
            padding: 40/750*100vw;
        }
    }
    &-M{
        padding: 88px 0 0;
        display: block;
        @include sp() {
            padding: 70/750*100vw;
        }
    }
    &-L{
        padding: 100px 0 0;
        display: block;
        @include sp() {
            padding: 120/750*100vw;
        }
    }
    &-XL{
        padding: 150px 0 0;
        display: block;
        @include sp() {
            padding: 180/750*100vw;
        }
    }
}
