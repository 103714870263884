.p-distBox{
	display: flex;
	flex-wrap: wrap;
	@include sp() {
		justify-content: space-between;

	}
	& > li{
		background: #fff;
		width: 258px;
		margin:16px 0 0 16px;
		padding: 1.5em  1em  2.5em ;
		display: flex;
		flex-direction: column;
		@include sp() {
			width: 330/750*100vw;
			margin:16px 0 0 ;

		}

		&:nth-child(4n+1){
			margin:16px 0 0 0;
		}
		img{
			display: block;
			width: auto;
			height: auto;
			margin: auto;
			max-width: 216px;
			max-height: 169px;


		}
	}
	&__name{
		padding-top: 1em;
		text-align: center;
		font-weight: bold;
		flex-grow: 1;
		min-height: 3em;
	}
	&__link{
		padding-top: .5em ;
		text-align: center;

	}

}
