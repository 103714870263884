
.p-comProf{
    &List{
        padding:30px 0;
        @include sp() {
            padding: 36/750*100vw 0;

        }
        &__box{
            background: #fff;
            padding: 54px 79px;
            display: flex;
            @include sp() {
                padding: 58/750*100vw;
                flex-direction: column;

            }
            & + &{
                margin-top: 17px;
                @include sp() {
                    margin-top: 36/750*100vw;

                }
            }
            a{
                display: block;
            }
        }
    }
    &Ttl{
        font-weight: bold;
        @include fs(16);
        width: 150px;

        @include sp() {
            @include vw(30);
            width: 100%;

        }
    }

    &Table{
        flex-grow: 1;
        position: relative;
        border-collapse: separate;
        display: table;
        width: 100%;
        @include sp() {
            margin-top: 32/750*100vw;

        }

        & tr{
            & + tr{
                th,
                td{
                    padding-top: 50px;
                    @include sp() {
                        padding-top: 40/750*100vw;

                    }
                }
            }
        }
        & th{
            border-right: 1px solid rgba($color: #707070, $alpha: .23);
            width: 150px;


            @include sp() {
                width: 194/750*100vw;

            }
        }

        &__ttl{
            font-weight: bold;
            text-align: right;
            padding-right:22px;
            vertical-align: top;
            p{
                font-weight: bold;
                @include fs(14);
                @include sp() {
                    @include vw(24);

                }
            }

        }
        &__data{
            padding-left:22px;
            width: calc(100% - 150px);
            @include sp() {
                width: 556/750*100vw;

            }
            p{
                @include fs(14);
                line-height: 1.2;
                @include sp() {
                    @include vw(28);
                }
                & + p{
                    padding-top: 0.4em;
                }
            }
        }

    }

}




