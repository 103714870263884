.l-footer {
    &INfo {
        flex-shrink: 0;
        flex-grow: 1;

        padding-left: 1em;
        max-width: (250 + 119)*1px;
        @include sp() {
            max-width: 100%;

        }

        &__logo {
            img {
                display: block;
                width: 250px;
                height: 50px;
                @include sp() {
                    width: 576/750*100vw;
                    height: 116/750*100vw;
                    margin: auto;
                }
            }
        }

        &__about {
            padding-top: 30px;
            @include sp() {
                text-align: center;

            }
        }
        &__name{
            @include fs(15);
            @include sp() {
                @include vw(34);

            }
        }
        &__zip{
            @include fs(13);
            padding-top: 20px;
            @include sp() {
                @include vw(28);
            }

        }
        &__address{
            @include fs(13);
            @include sp() {
                @include vw(28);
            }

        }
        &__phone{
            @include fs(13);
            @include sp() {
                @include vw(28);
            }

        }

        &__btn {
            display: flex;
            padding-top: 30px;
            @include sp() {
                justify-content:center;

            }
        }

        &__tw {
            img {
                display: block;
                width: 35px;
                height: 28px;
                @include sp() {
                    width: 105/750*100vw;
                    height: 83/750*100vw;

                }
            }
        }

        &__yt {
            padding-left: 26px;
            @include sp() {
                padding-left: 76/750*100vw;

            }
            img {
                display: block;
                width: 39px;
                height: 28px;
                @include sp() {
                    width: 119/750*100vw;
                    height: 82/750*100vw;

                }
            }
        }

    }


}
