.m-pagenation{
	text-align: center;
	.page-numbers{
		display: inline-block;
		padding: 0.5em 1em;
		line-height: 1;
		@include fs(14);
		color: #999;

	}
	.current{
		font-weight: bold;
		// font-size: 120%;
		color: $color_txt;
	}
	a:hover{
		text-decoration: underline;
		color: $color_txt;

	}
	a,
	span{
		vertical-align: middle;
		display: inline-block;
	}
	.prev,
	.next{
		color: $color_txt;
		border: 1px solid #DBDFE6;
		&:hover{
		text-decoration: none;
		background: #DBDFE6;
	}
	}
}
