.p-top {

    &Kv{
        max-height: 608px;
        overflow: hidden;
        position: relative;
        min-width: 1080px;
        @include sp() {
            min-width:auto;
            max-height: 770/750*100vw;

        }

        &__wrap{
            display: block;
            width: 100%;
            height: 0px;
            padding: (608/1366)*100% 0 0;
            overflow: hidden;
            position: relative;
            @include sp() {
                padding: 770/750*100vw 0 0;


            }

        }
		&__emergency{
        	background: #242424;
			background: rgba($color: #242424, $alpha: .9);
			position: absolute;
			display: block;
			top: 0;
			left: 0;
			width: 100%;
			z-index: 1;
			text-align: center;
			padding: 14px;
			@include sp() {
				padding: 32/750*100vw;


			}

			a{
				color: #fff;
				display: inline-block;
				line-height: 1.2;
				position: relative;
				padding-right: 1em;
				// text-align: right;
				text-align: left;
				&::before {
					content:"";
					display: inline-block;
					width: 4px;
					height: 4px;
					border-top: 2px solid #CF1721;
					border-right: 2px solid #CF1721;
					position: absolute;
					top: 50%;
					right: 0;
					margin-right: 0;
					transform: translate(0, -50%) rotate(45deg);
					transition: margin 300ms 0s ease;
				}
				&:hover::before {
					margin-right: -5px;
				}
			}

		}
        &__main{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            // img{
            //     width: 100%;
            //     display: block;
            // }

        }
        &__mvWrap{
            position: relative;
            height: 100%;
            width: 100%;
            &::after {
                content:"";
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background:transparent url(#{$imgPath}top/pattern.png) repeat left 50%;
                opacity: .5;
                background-size: 4px;
            }
        }
        &__mv{
            @include pc() {

            width: 100%;
            display: block;
            position: absolute;
            // top: 0;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            // transform: translateX(-50%);
            }

            @include sp() {
            height: 100%;
            display: block;
            position: absolute;
            // top: 0;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            // transform: translateX(-50%);

            }
        }

        &__txtWrap{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;

        }
        &__txt01{
            color: #fff;
            font-family: $font-family-Barlow;
            font-weight: 800;
            @include fs(38);
            line-height: 1;
            text-align: center;
            letter-spacing: .2em;
            text-shadow:rgba($color: #000010, $alpha: .3) 0 0 15px;

            @include sp() {
                @include vw(50);
                letter-spacing: 3px;

            }

        }
        &__txt02{
            color: #fff;
            @include fs(21);
            display: block;
            font-weight: bold;
            line-height: 1;
            text-align: center;
            letter-spacing: .4em;
            padding-top: 1em;
            text-shadow:rgba($color: #000010, $alpha: .3) 0 0 10px;
            @include sp() {
                @include vw(25);
                letter-spacing: 4px;
            }

        }
    }
}
