.p-faq{
	border-top: 1.2px solid  rgba($color: #707070, $alpha: .3);
	&__item{
		border-bottom: 1.2px solid  rgba($color: #707070, $alpha: .3);
		// padding: 1em 0;

	}
	&__ctgTxt{
		font-weight: bold;
		padding: 1em 0;
            @include fs(20);
            @include sp() {
                @include vw(28);
		}
	}
	&__qTxt{
		cursor: pointer;
		padding: 1.3em 3em 1.3em 0 ;
		font-weight: bold;
		position: relative;
		transition: all 300ms 0s ease;

		@include fs(16);
		@include sp() {
			@include vw(24);
		}
		&::after,
		&::before {
			content:"";
			display: inline-block;
			background: #242424;
			position: absolute;
			top: 50%;
			right: 0;
		}

		&::before {
			width: 12px;
			height: 2px;
			transform: translate(0, -50%) ;
			margin-right: 1em;

		}
		&::after{
			width: 2px;
			height: 12px;
			transform: translate(0, -50%) ;
			transition: all 300ms 0s ease;
			margin-right:calc( 1em + 5px) ;

		}
		&.is-open{
			padding:2.5em 3em  2em  0;

			&::after{
				height: 0;

			}
		}


	}
	&__aTxt{
		@include fs(14);
		@include sp() {
			@include vw(24);
		}
		img{
			display: block;
			max-width: 70%;
			width: auto;
			max-height: 380px;
			height: auto;
			padding-bottom: 1em;
			&.js-popUpImg{
				cursor: pointer;
				&:hover{
					opacity: .7;
				}

			}
		}
		div,
		p{
			padding-bottom: 0.4em;
			font-size: inherit;
		}
		a{
			text-decoration: underline;
			font-size: inherit;
		}

        a[href$="ZIP"],
        a[href$="zip"],
        a[href$="PDF"],
        a[href$="pdf"]{
            text-decoration: none;
            position: relative;
            padding-right:20px;
            font-weight: bold;
            @include fs(12);
            @include sp() {
                @include vw(28);
            }

            &::before {
                content:"";
                display: inline-block;
                width: 14px;
                height: 14px;
                position: absolute;
                top: 50%;
                right: 0;
                margin-right: 0;
                transform: translate(0, -50%);
                background: #ccc;
                background:transparent url(#{$imgPath}common/icon_dl.svg) no-repeat bottom center;
                background-size: contain;
            }

        }


	}
	&__body{
		display: none;
		padding: 0 3em 4em 0;
		@include sp() {
			padding: 0 0 4em 0;

		}

	}
	&__tagBlock{
		padding-top: 1em;
		@include fs(14);
		@include sp() {
			@include vw(24);
		}
		a{
			text-decoration: underline;
			font-size: inherit;
		}
	}


}
