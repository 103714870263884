.m-txtB{
	@include fs(16);
	line-height: 1.8;
	color: #242424;
	@include sp() {
		@include vw(32);

	}

}
