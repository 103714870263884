.p-prdDetSpec{


	@include pc() {
		display: inline-flex;
		padding-top: 100px;
		.p-news__inDetails & {
			padding-top: 50px;
		}
	}

	@include sp() {
		// width: 680/750*100vw;
		width: 100%;
		margin: auto;
		display: block;
		padding: 0;
	}
	// 製品の特長
	&__ttl{
		font-weight: bold;
		// padding-right:  38px;
		width: 120px;
		padding: .4em 0;
		@include fs(14);

		@include sp() {
			padding-top: 4em;
			@include vw(30);
			width: 100%;


		}

	}
	&__listWrap{
		@include pc() {
			border-left: 1px solid #707070;
		}
		@include sp() {
			border-top: 1px solid #707070;
			padding-top: 1em;

		}
		ul{
			max-width: 600px;
			@include sp() {
				max-width: 100%;
				width: 100%;

			}
			& > li{
				display: block;
				position: relative;
				padding: .4em 0 .4em 1em;
				margin-left: 30px;
				@include fs(12);
				// min-width: 40%;
				// vertical-align: top;
				line-height: 1.2;
				@include sp() {
					margin-left: 0;
					@include vw(24);

				}

				&::before {
					content:"";
					display: inline-block;
					width: 2px;
					height: 2px;
					background: #000;
					position: absolute;
					top: 1em;
					left: 0;
					transform: translate(0, -.1em) ;
				}
			}
		}

	}
}
