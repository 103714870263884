.p-ptn{
	&__head{
		@include pc() {
			display: flex;
		}
	}

	&__logo{
		display: block;
		width: 250px;
		flex-shrink: 0;
		padding-right: 50px;
		@include sp() {
			width:100%;
		}

		img{
			max-width: 100%;
			display: block;
			@include sp() {
				max-width: 60%;
				max-height: 175/750*100vw;
				width: auto;

			}
		}
	}
	&__txt{
		line-height: 1.8;
		@include fs(14);
		@include sp() {
			@include vw(28);
			padding-top: 2em;
		}
	}
	&__body{
		@include pc() {
			display: flex;
		}
		margin-top: 40px;
		padding-top: 40px;
		border-top: 1px solid rgba($color: #707070, $alpha: .3);
		@include sp() {
			margin-top: 40/750*100vw;
			padding-top: 40/750*100vw;

		}
	}
	&__dTtl{
		width: 250px;
		font-weight: bold;
		flex-shrink: 0;
		@include fs(16);
		@include sp() {
			@include vw(28);
			width: 100%;
		}
	}
	&__dataBlock{
		flex-grow: 1;

	}



	&__pLink{
		margin-top:90px;
		text-align: right;
		width: 100%;
		text-align: right;
		position: relative;
		line-height: 1;

		@include fs(12);
		@include sp() {
			@include vw(20);
			flex-shrink: 0;
			margin-top:100/750*100vw;
			align-self:flex-end;
		}
		&::before {
			content:"";
			display: inline-block;
			width: 4px;
			height: 4px;
			border-top: 2px solid #CF1721;
			border-right: 2px solid #CF1721;
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 0;
			transform: translate(0, -50%) rotate(45deg);
			transition: margin 300ms 0s ease;

		}

		a:hover &::before,
		&:hover::before {
			margin-right: -5px;
		}

		a{
			display: inline-block;
		}

		&__linkTxt{
			line-height: 1;
			@include fs(12);
			letter-spacing: 0.7px;
			font-family: $font-family-Barlow;
			padding-right: 15px;
			font-weight: 600;
			@include sp() {
				@include vw(20);
				padding-right: 1em;
				display: inline-block;


			}

		}

	}
}
