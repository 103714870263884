.m-newsList{


        padding: 20px 40px;
		&--inPage{
	        padding: 0 0 40px;
		}
        @include sp() {
            padding: 20/750*100vw 40/750*100vw;


        }
        & > li{
            padding: 20px 0;
            display: flex;
            // align-items:center;
            @include sp() {
                flex-wrap: wrap;
                padding: 20/750*100vw 0;
				// justify-content: space-between;



            }
            & + li{
                border-top: 1px solid #D9D9D9;
            }
        }
        &__date{
            color: #242424;
            @include fs(14);
            flex-shrink: 0;
            display: inline-block;
            line-height: 1.4;


            @include sp() {
                @include vw(24);
            }
        }
        &__ctg{
            margin:0 20px;
            background: #242424;
            color: #fff;
            @include fs(11);
            display: flex;
            justify-content:center;
            align-items:center;

            line-height: 1;
            padding:6px 10px;
            height: 100%;
            display: inline-flex;
			width: 10em;

            flex-shrink: 0;
            @include sp() {
                @include vw(20);
				margin:0 0 0 20px;
            }

        }
        a{
            display: inline-block;
            line-height: 1;

            &:hover{
				.m-newsList__txt{
	                text-decoration: underline;
				}
            }
        }
        &__txt{
            flex-grow: 1;
            color: #242424;
            @include fs(14);
            display: inline-block;
            line-height: 1.4;

            @include sp() {

                width: 100%;
				min-width: 500/750*100vw;
                @include vw(24);
                padding-top: .2em;

            }

        }

}
