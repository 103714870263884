.p-ptndList{
	display: flex;
	flex-wrap: wrap;
	margin-top: -1em;
	@include sp() {
		margin-top: 1em;

	}

	& > dt {
		width: 5em;
		font-weight: bold;
		padding-top: 1em;
		@include fs(14);
		@include sp() {
			@include vw(28);
		}
	}
	& > dd {
		width:calc(100% - 8em) ;
		padding-top: 1em;
		@include fs(14);
		@include sp() {
			@include vw(28);
		}
	}
	a {
		text-decoration: underline;
	}
}
