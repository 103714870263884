.p-prdDetDL{
	@include pc() {
		display: flex;
		flex-direction: column;
		flex-wrap:wrap;
		height: 310px;
	}
	@include sp() {
		text-align: center;

	}


	& > li{
		display: block;
		width: 84px;
		padding-top: 1em;
		@include sp() {
			display: inline-block;
			padding-top: 0;

		}



	}
	a{
		display: block;
		text-align: center;
		margin: auto;
	}
	&__icon{
		display: block;
		width: 62px;
		height: 62px;
		margin: auto;
		.p-news__inDetails & {
			width: 48px;
			height: 48px;
		}



		@include sp() {
			width:100/750*100vw;
			height:100/750*100vw;
		}

		&--PDF{
			background:transparent url(#{$imgPath}common/icon_c_pdf.svg) no-repeat bottom center;
			background-size: contain;
			a:hover > &{
				background:transparent url(#{$imgPath}common/icon_c_pdf_on.svg) no-repeat bottom center;
				background-size: contain;
			}
		}
		&--ZIP{
			background:transparent url(#{$imgPath}common/icon_c_zip.svg) no-repeat bottom center;
			background-size: contain;
			a:hover > &{
				background:transparent url(#{$imgPath}common/icon_c_zip_on.svg) no-repeat bottom center;
				background-size: contain;
			}
		}
		&--CAD{
			background:transparent url(#{$imgPath}common/icon_c_cad.svg) no-repeat bottom center;
			background-size: contain;
			a:hover > &{
				background:transparent url(#{$imgPath}common/icon_c_cad_on.svg) no-repeat bottom center;
				background-size: contain;
			}
		}
		&--FAQ{
			background:transparent url(#{$imgPath}common/icon_c_faq.svg) no-repeat bottom center;
			background-size: contain;
			a:hover > &{
				background:transparent url(#{$imgPath}common/icon_c_faq_on.svg) no-repeat bottom center;
				background-size: contain;
			}
		}



	}
	&__txt{
		display: block;
		text-align: center;
		@include fs(12);
		line-height: 1;
		padding-top: 4px;
		height: 2em;
			a:hover > &{
				color:$color_red;

			}
		@include sp() {
			@include vw(17);

		}
	}

}
