.l-headerSch{
	@include pc() {


    display: none;
    width: 100%;
    position: absolute;
    bottom: 100%;
    top: 0;
    left: 0;
    right: 0;
    padding: 25px 0;
    // background: #ccc;
    background: #242424;
    // color: #fff;
    text-align: center;
    transition: all 300ms 0s ease;
    z-index: -1;

    &.is-open{
        display: block;
        top: 100%;
        bottom: auto;


    }


    label{
        color: #fff;
    }
    input{
        color: #242424;
		border: none;
		outline: none;
		&:focus{
			background: #edeff2;
			border: none;
			outline: none;
		}
    }
    button{
        color: #242424;
    }

    &__form{
        margin: auto;
        display: inline-block;
    }
	&__innerBox{
		background: #fff;
		display: inline-flex;
		align-items:center;
		padding:5px 10px;
	}
	input[type=search],
	&__field{
		border: none;
		outline: none;
		width: 43em;
		padding:5px 10px;

		line-height: 1;

		@include fs(16);
		@include sp() {
			@include vw(32);
		}
	}
	&__ctg{
		border: none;
		padding:5px 10px;
		@include fs(12);
		line-height: 1;
		margin:0 0 0 10px;
		display: block;

		@include sp() {
			@include vw(32);
		}


		&:focus{
			background: #edeff2;
			border: none;
			outline: none;
		}
	}
	&__btn{
		margin:0 0 0 10px;
		background:transparent ;
		border: none;
		cursor: pointer;
		padding: 10px;
			img{
				width: 22px;
			}
		}
	}
}
