.p-comOemPlan{
	padding: 48px 0 0;
	display: flex;
	justify-content: space-between;
	@include sp() {
		flex-direction: column;
		padding-top: 76/750*100vw;

	}
	& > li{
		width: 344px;
		display: flex;
		flex-direction: column;
		@include sp() {
			width: 100%;
			& + li{
				padding-top: 76/750*100vw;
			}
		}
	}
	&__fuki{
		background:transparent url(#{$imgPath}company/bg_oem_fukidashi.svg) no-repeat bottom center;
		background-size: 100% auto ;
		padding:0 0 25px;
		@include sp() {
			padding:0 0 55/750*100vw;



		}

	}
	&__fukiTxt{
		display: block;
		background: #fff;
		padding:24px;
		text-align: center;
		font-weight: bold;
		@include fs(16);
		@include sp() {
			@include vw(32);

		}
	}
	&__img{
		margin-top: 28px;
		height: 205px;
		display: block;
		position: relative;
		@include sp() {
			// width: 678/750*100vw;
			width: 100%;
			height: 404/750*100vw;

		}

		&--a{
			background:transparent url(#{$imgPath}company/bg_oem_a.jpg) no-repeat top center;
			background-size: contain;
		}
		&--b{
			background:transparent url(#{$imgPath}company/bg_oem_b.jpg) no-repeat top center;
			background-size: contain;
		}
		&--c{
			background:transparent url(#{$imgPath}company/bg_oem_c.jpg) no-repeat top center;
			background-size: contain;
		}

	}
	&__tag{
		width: 62px;
		display: block;
		position: absolute;
		left: 30px;
	}
	&__box{
		background: #fff;
		padding:40px 32px 32px;

		flex-grow: 1;


		// height: 100%;

	}
	&__boxTtl{
		text-align: center;
		font-weight: bold;
		@include fs(20);
		@include sp() {
			@include vw(36);

		}
	}
	&__boxTxt{
		padding-top: 32px;
		@include fs(14);

		@include sp() {
			@include vw(32);

		}
	}

}
