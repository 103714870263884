.m-linkA{
    width: 100%;
    text-align: right;
    position: relative;
    line-height: 1;
	display: inline-block;
    @include fs(12);
    @include sp() {
        @include vw(28);
        width: 150/750*100vw;
        flex-shrink: 0;
        height: 1em ;
        align-self:flex-end;
    }
	&--rev{
		align-self:flex-start;
		text-align: left;
		    &::before {
				position: relative;
			}
	}
    &::before {
        content:"";
        display: inline-block;
        width: 4px;
        height: 4px;
        border-top: 2px solid #CF1721;
        border-right: 2px solid #CF1721;
        position: absolute;
        top: 50%;
        right: 0;
        margin-right: 0;
        transform: translate(0, -50%) rotate(45deg);
        transition: margin 300ms 0s ease;

    }

    a:hover &::before,
    &:hover::before {
        margin-right: -5px;
    }


    &__linkTxt{
        line-height: 1;
        @include fs(12);
        letter-spacing: 0.7px;
        font-family: $font-family-Barlow;
        padding-right: 15px;
        font-weight: 600;
        @include sp() {
            @include vw(28);

        }

    }
	&--back{
	    text-align: left;

		&::before {
			content:"";
			display: inline-block;
			width: 4px;
			height: 4px;
			border-top: 2px solid #CF1721;
			border-right: 2px solid #CF1721;
			position: absolute;
			top: 50%;
			right:auto;
			left: 0;
        	margin-right: 0;
			margin-left: 0;
			transform: translate(0, -50%) rotate(-135deg);
			transition: margin 300ms 0s ease;

			a:hover &::before,
			&:hover::before {
	        	margin-right: 0;
				margin-left: -5px;
			}

		}
		.m-linkA__linkTxt{
	        padding-left: 15px;
        	padding-right: 0;

		}

	}
	&--backR{
	    text-align: right;
		&::before {
            display: none;
        }

		.m-linkA__linkTxt{
	        padding-left: 15px;
        	padding-right: 0;
            position: relative;

            &::before {
                content:"";
                display: inline-block;
                width: 4px;
                height: 4px;
                border-top: 2px solid #CF1721;
                border-right: 2px solid #CF1721;
                position: absolute;
                top: 50%;
                right:auto;
                left: 0;
                margin-right: 0;
                margin-left: 0;
                transform: translate(0, -50%) rotate(-135deg);
                transition: margin 300ms 0s ease;

                a:hover &::before,
                &:hover::before {
                    margin-right: 0;
                    margin-left: -5px;
                }

            }

		}

	}





}
