@charset "utf-8";
.l-headerNav{
	@include pc() {

    	height: 72px;
        display: flex;
        justify-content: space-between;

		&>li{
			& + li{
				margin-left: 3em;

			}
		}
		&__schicon,
		a{
			height: 72px;
			display: flex;
			align-items:center;
			position: relative;
			font-weight: bold;
			cursor: pointer;
			.is-open,
			&:hover{
				&::before {
					content:"";
					display: inline-block;
					width: 100%;
					height: 4px;
					position: absolute;
					background: $color_red;
					bottom: 0;
					right: 0;
				}
			}
		}

		&__schicon{
			img{
				width: 20px;
				display: inline-block;

			}
		}

	}


}
