.l-footer {
    &Link {
        padding-left: 1em;

        &__ttl{
            padding: 0 0 .5em 0;
        }
        &__sub{
            color: rgba($color: #fff, $alpha: .5);
            @include fs(13);
            padding: 1em 0 .5em 0;
            line-height: 1;

            &::before {
                content:"";
                display: inline-block;
                width: 1em;
                height: .5em;
                border-bottom: 1px solid  rgba($color: #fff, $alpha: .5);
                margin-right: .5em;
                vertical-align: top;
            }

        }
        &__list{
            padding: 0 1em 1em 0;

            & > li{
                padding-top: 1em;
                line-height: 1;
            }
            a {
                color: #fff;
                color: rgba($color: #fff, $alpha: .5);
                @include fs(13);

                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &__inBox{
            display: flex;
        }

    }
}
