

.p-comBiz{
    padding: 90px 0 100px;
    &__pol{}
    &__mdl{
        padding-top: 100px;
        @include sp() {
            padding-top: 120/750*100vw;

        }
    }
    &Ttl{
        &__sub{
            color: #CF1721;
            display: block;
            font-family: $font-family-Barlow;
            line-height: 1;
            @include fs(17);
            position: relative;
            padding-left: 35px;
            letter-spacing: 1.7px;

            @include sp() {
                @include vw(28);

            }
            &::before {
                content:"";
                display: inline-block;
                width: 28px;
                height: 2px;
                background: #CF1721;
                position: absolute;
                top: 50%;
                left: 0;
                margin-right: 1em;
                transform: translate(0, -50%);
                @include sp() {
                    height: 4px;


                }
            }
        }
        &__txt{
            font-weight: bold;
            @include fs(22);
            font-weight: bold;
            display: block;
            letter-spacing: 2.2px;
            @include sp() {
                @include vw(42);
                padding-top: 10px;

            }
        }
    }
    &__txt{
        padding-top: 40px;
        @include fs(16);
        line-height: 2;
    }
    &List{
        padding-top: 50px;
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;

        }
        & > li{
            background: #fff;
            width: 344px;
            width: calc(25% - 18px);

            padding:30px 22px 50px;
            @include sp() {
                padding: 60/750*100vw;
                width: 100%;
                margin-top: 1em;
            }

        }
        &__ttl{
            font-family: $font-family-Barlow;
            font-weight: 800;
            color: #CF1721;
            line-height: 1;


        }
        &__num{
            display: block;
            text-align: center;
            @include fs(40);
            @include sp() {
                @include vw(90);
            }

        }
        &__sub{
            display: block;
            text-align: center;
            padding-top: 8px;
            @include fs(12);
            @include sp() {
                @include vw(22);
            }


        }
        &__read{
            text-align: center;
            font-weight: bold;
            padding-top: 34px;
            @include fs(16);
            @include sp() {
                @include vw(36);
            }
        }
        &__txt{
            padding: 34px 8px 0;
            line-height: 2;
            @include fs(14);
            letter-spacing: .1em;
            @include sp() {
                @include vw(28);
            }
        }
    }
    &__img{
        text-align: center;
        display: block;
        padding-top: 86px;
        @include sp() {
            padding-top: 60/750*100vw;


        }
    }

}
