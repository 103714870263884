@charset "utf-8";


.p-topLink{
    background: #EDEFF2;
    padding: 100px 0;
    min-width: 1080px;
    @include sp() {
        min-width:auto;
    }

    &__inner{
        max-width: 1080px;
        min-width: 1080px;
        margin: auto;
        @include sp() {
            width: 680/750*100vw;
            min-width: auto;
        }
    }
    &List{
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
        }

        & > li{
            width: 532px;
            position: relative;
            background: #fff;
            @include sp() {
                width: 100%;
                & + li{
                    margin-top: 1em;
                }
            }

            &:hover{
                box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .1);
            }
        }
        a{
            display: block;
            width: 100%;
            height: 100%;

            &:hover{
                opacity: 1;
            }
        }


        &__img{
            display: block;
			width: 100%;
        }
        &__ttl{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            padding: 24px;
            display: block;
            width: 100%;


            @include sp() {
                padding: 40/750*100vw;
            }
        }
        &__sub{
            color: #CF1721;
            width: 100%;
            display: block;
            text-align: center;
            font-family: $font-family-Barlow;
            line-height: 1;
            font-weight: 800;
            letter-spacing: .1em;
            @include fs(24);
            @include sp() {
                @include vw(42);

            }
        }
        &__txt{
            color: #fff;
            width: 100%;
            display: block;
            text-align: center;
            font-weight: bold;
            @include fs(15);
            @include sp() {
                @include vw(26);

            }
        }

    }

}
