
.p-com{
    &List{
        padding:30px 0;
        @include sp() {
            padding: 36/750*100vw 0;

        }

        &> li{
            background: #fff;
            & + li{
                margin-top: 17px;
                @include sp() {
                    margin-top: 36/750*100vw;

                }
            }
            a{
                display: block;
            }
        }
        &__wrap{
            display: flex;

            li:nth-child(even) & {
                flex-direction: row-reverse;
                @include sp() {
                    flex-direction: column;
                }
             }

            @include sp() {
                flex-direction: column;
            }

        }
        &__img{
            img{
                display: block;
                width: 757px;
                @include sp() {
                    width: 100%;

                }
            }

        }
        &__main{
            padding: 63px 59px 45px 64px;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            @include sp() {
                padding: 58/750*100vw 55/750*100vw 58/750*100vw 59/750*100vw;

            }



        }
        &__txt{
            padding-top: 22px;
            @include fs(12);
            flex-grow: 1;
            @include sp() {
                padding-top: 24/750*100vw;
                @include vw(28);

            }

        }
        &__link{
            text-align: right;
            position: relative;
            &::before {
                content:"";
                display: inline-block;
                width: 5px;
                height: 5px;
                border-top: 2px solid #CF1721;
                border-right: 2px solid #CF1721;
                position: absolute;
                top: 50%;
                right: 0;
                margin-right: 0;
                transform: translate(0, -50%) rotate(45deg);
                transition: margin 300ms 0s ease;

                a:hover &{
                    margin-right: -5px;
                }
            }
            @include sp() {
                margin-top: 24/750*100vw;

            }


        }
        &__linkTxt{
            @include fs(14);
            letter-spacing: 0.7px;
            font-family: $font-family-Barlow;
            padding-right: 15px;
            font-weight: 600;
            @include sp() {
                @include vw(28);

            }

        }
    }
    &Ttl{
        &__txt {
            font-family: $font-family-Barlow;
            color: #CF1721;
            @include fs(30);
            letter-spacing: 3px;
            display: block;
            line-height: 1;
            @include fs(38);
            @include sp() {
                @include vw(38);
            }
        }

        &__sub {
            font-weight: bold;
            @include fs(14);
            display: block;
            line-height: 1;
            letter-spacing: 1.4px;
            color: #242424;
            padding-top: 10px;
            @include fs(18);
            @include sp() {
                @include vw(26);
            }
        }
    }


}
