.p-sptList{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        & > li{
            width: calc(50% - 8px);
			min-height: 220px;
            display: block;
            padding-top: 16px;
			@include sp() {
				width: 100%;

			}
			&:nth-child(1){
				padding-top: 0;
			}
			@include pc() {
				&:nth-child(2){
					padding-top: 0;
				}
			}

        }
		a{
			display: block;
            background: #fff;
			height: 100%;
            padding: 46px 24px 26px ;
			display: flex;
			flex-direction: column;
			@include sp() {
            	padding: 75/750*100vw 58/750*100vw 59/750*100vw 24/750*100vw;
			}

			&:hover{
				opacity: 1;
			}
		}
		&__block{
			display: flex;
			flex-grow: 1;


			// flex-wrap: wrap;
		}
		&__iconBox{
			width: 30%;
			flex-shrink: 0;
			text-align: center;
			@include pc() {
				align-self:center;
			}
			span{
				@include fs(14);
				color: #747474;
		        font-family: $font-family-Barlow;
				padding-top: .5em;
				display: inline-block;
				// font-weight: bold;
				@include sp() {
					@include vw(21);

				}

			}

		}

		&__txtBox{
			// width: 70%;
			flex-grow: 1;
			border-left: 1px solid  rgba($color: #707070, $alpha: .3);
			padding:0 37px;
			@include sp() {
				padding:0 0 0 42/750*100vw;

			}
			p{
				@include fs(14);
			}


		}
		&__icon{
			display: block;
			margin: auto;
			width: 50px;
			height: 40px;

			&--faq{
				background:transparent url(#{$imgPath}common/icon_s_faq.svg) no-repeat 50% center;
				background-size: 48px 38px;
			}
			&--soft{
				background:transparent url(#{$imgPath}common/icon_s_soft.svg) no-repeat 50% center;
				background-size: 35px 30px;
			}
			&--man{
				background:transparent url(#{$imgPath}common/icon_s_man.svg) no-repeat 50% center;
				background-size: 30px 37px;
			}
			&--con{
				background:transparent url(#{$imgPath}common/icon_s_con.svg) no-repeat 50% center;
				background-size: 34px 22px;
			}
		}
}
