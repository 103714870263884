
    // 製品一覧
.p-topPrd{
    padding: 30px 0;
    a{
        display: block;
        &:hover{
            opacity: 1;
        }
    }
    &__inner{
        max-width: 1306px;
        // min-width: 1306px;
        min-width: 1080px;

        margin: auto;
        @include sp() {
            min-width: auto;
            width: 680/750*100vw;
        }
    }
    &__list{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }
    &__item{
        margin-top: 15px;
        overflow: hidden;
        background: #dbe0e6;
        // &:hover{
        //     box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .2);
        // }
        @include pc() {


            &--analoghd,
            &--hdsdi,
            &--analog{
                max-width: 33%;
            }
            &--net,
            &--monitor,
            &--acces,
            &--exit{
                max-width: 24%;
            }

        }

        &--analoghd,
        &--analog,
        &--monitor,
        &--exit{
            color: #fff;
            background: #000;

            a{
                color: #fff;

            }
        }
        @include sp() {
            &--hdsdi,
            &--net,
            &--acces{
                text-align: right;

            }

        }
        &:hover{
            .p-topPrd__cover{
                position: absolute;
                top: 0;
                left: 0;
            }
            .p-topPrd__ttl02{
                position: relative;
                bottom: auto;
            }
            // .p-topPrd__sub,
            // .p-topPrd__txt{
            //     color: #fff;
            // }
            .p-topPrd__subList{
                display: flex;
                flex-direction: column;
                align-items:flex-start;

            }
        }
    }

    &__cover{
        position: absolute;
        background: #CF1721;
        // position: relative;
        display: block;
        height: 100%;
        width: 100%;
        top: 100%;
        left: 0;
        transition: top 300ms 0s ease;
        @include sp() {
            display: none;

        }
    }
    &__subList{
        display: none;
        padding: 0 24px;
    }

    &__link{
        position: relative;
        line-height: 1;
        @include fs(14);
        display: inline-block;
        & + & {
            margin-top: 1em;
        }
        &::before {
            content:"";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-top: 2px solid #fff;
            border-right: 2px solid #fff;
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 0;
            transform: translate(0, -50%) rotate(45deg);
            transition: margin 300ms 0s ease;
        }
        &:hover::before {
            margin-right: -5px;
        }
    }
    &__linkTxt{
        line-height: 1;
        @include fs(14);
        font-weight: bold;
        padding-right: 15px;
        color: #fff;
    }
    &__box{
        display: block;
        position: relative;
    }
    &__img{
        display: block;
        @include pc() {


            .p-topPrd__item--analoghd &,
            .p-topPrd__item--hdsdi &,
            .p-topPrd__item--analog &{
                height: 425px;
                width: 425px;
                display: block;
                max-width: 100%;
                position: relative;
                // position: absolute;
                // left: 50%;
                // top: 0;


                img{
                    position: absolute;
                    display: block;
                    width: 100%;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                }

            }


        }

        @include sp() {
            display: block;
            width: 100%;
            source{
                display: block;
                width: 100%;
            }

        }
    }

    &__ttl{
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 24px;
        @include sp() {
            padding: 34/750*100vw 25/750*100vw;

            .p-topPrd__item--hdsdi &,
            .p-topPrd__item--net &,
            .p-topPrd__item--acces &{
                right: 0;
                left: auto;
            }



        }

    }

    &__sub{
        color: #CF1721;
        display: block;
        font-family: $font-family-Barlow;
        line-height: 1;
        font-weight: 800;
        @include fs(28);
        @include pc() {
            .p-topPrd__item--net &,
            .p-topPrd__item--monitor &,
            .p-topPrd__item--exit &,
            .p-topPrd__item--acces &{
                @include fs(26);
            }
        }


        @include sp() {
            @include vw(34);

        }
    }
    &__txt{
        font-weight: bold;
        color: inherit;
        @include fs(16);
        @include pc() {
            .p-topPrd__item--net &,
            .p-topPrd__item--monitor &,
            .p-topPrd__item--exit &,
            .p-topPrd__item--acces &{
                @include fs(14);
            }
        }
        @include sp() {
            @include vw(24);

        }
    }

    &__ttl02{
        // position: absolute;
        // bottom: 100%;
        left: 0;
        padding: 24px;
        @include sp() {
            padding: 34/750*100vw 25/750*100vw;

            .p-topPrd__item--hdsdi &,
            .p-topPrd__item--net &,
            .p-topPrd__item--acces &{
                right: 0;
                left: auto;
            }



        }

    }

    &__sub02{
        color: #fff;
        display: block;
        font-family: $font-family-Barlow;
        line-height: 1;
        font-weight: 800;
        @include fs(28);
        @include pc() {
            .p-topPrd__item--net &,
            .p-topPrd__item--monitor &,
            .p-topPrd__item--exit &,
            .p-topPrd__item--acces &{
                @include fs(26);
            }
        }

    }
    &__txt02{
        font-weight: bold;
        color: inherit;
        color: #fff;
        @include fs(16);
        @include pc() {
            .p-topPrd__item--net &,
            .p-topPrd__item--monitor &,
            .p-topPrd__item--exit &,
            .p-topPrd__item--acces &{
                @include fs(14);
            }
        }

    }



}
