.p-dl{
    &Page{
        img{
            display: block;
            margin: auto;
            padding: 1em;
        }
        p{
            padding-top: 1.4em;
            @include fs(14);
            @include sp() {
                @include vw(28);
            }
        }
        a[href$="ZIP"],
        a[href$="zip"],
        a[href$="PDF"],
        a[href$="pdf"]{
            // text-decoration: underline;
            position: relative;
            padding-right:20px;
            font-weight: bold;
            @include fs(12);
            @include sp() {
                @include vw(28);
            }

            &::before {
                content:"";
                display: inline-block;
                width: 14px;
                height: 14px;
                position: absolute;
                top: 50%;
                right: 0;
                margin-right: 0;
                transform: translate(0, -50%);
                background: #ccc;
                background:transparent url(#{$imgPath}common/icon_dl.svg) no-repeat bottom center;
                background-size: contain;
            }

        }
    }
    &BgBoxWrap{
        //  要素カウント用
        display: block;
    }
    &BgBox{
        padding:0 90px 90px;
        background: #fff;
        @include sp() {
            padding: 0 40/750*100vw 100/750*100vw;
        }
        &:first-child{
            padding-top: 90px;
            @include sp() {
                padding-top: 90/750*100vw;
            }

        }
    }

}
