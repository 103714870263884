@charset "utf-8";
.l-header{
    position: relative;
    display: block;
    @media print {
        display: none;
    }
    &::before {
        content:"";
        display: block;
        height: 72px;
        width: 100%;
        position: relative;
        @include sp() {
            height: 110/750*100vw;
        }
    }
    &__wrap{
        box-shadow: 0px 3px 10px rgba($color: #000029, $alpha: .15) ;
        position: fixed;
        height: 72px;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background: #fff;
        @include sp() {
            height: 110/750*100vw;
        }

    }

	&__inner{
		width: 100%;
		max-width: 1366px;
		min-width: 1080px;

		display: block;
		margin: auto;
        display: flex;
        justify-content: space-between;
        @include sp() {
            min-width: auto;
			height: 100%;

        }


	}
    &__logo{
        display: block;
        padding: 18px 0 0 23px;
        flex-grow: 1;
		a{
			display: inline-block;
		}
        img{
            width: 176px;
            height: 36px;
            display: block;
            @include sp() {
                width: 265/750*100vw;
                height: 54/750*100vw;

            }
        }
    }
    &__navWrap{
		@include pc() {
			display: block;
			// width: 100%;
			padding-right: 60px;
		}
        @include sp() {
			display: block;
			z-index: 10;
			padding: 74/750*100vw;
            display: none;
			width: 100%;
			height: 100vh;
			position: fixed;
			overflow: auto;
			top: 0;
			left: 0;
			background: rgba($color: #CF1721 , $alpha: .95);

			&.is-open{
				display: block;

			}

        }
    }

}
