/*
#overview
m-/ttl/ 見出し01

m-ttl01

タグはh1~h6、div、p、span も可能。文脈に合わせて使用する
*/

/*
#styleguide
見出し01

.m-ttl01

```
<div class="m-ttl01">
  <h1 class="m-ttl01__text">見出し</h1>
</div>
```
*/


.m-titleA {
    // TOP
    &__txt {
        font-family: $font-family-Barlow;
        color: #CF1721;
        @include fs(38);
        display: block;
        line-height: 1;
        letter-spacing: 3.8px;
        font-weight: 800;

        @include sp() {
            @include vw(56);
        }

    }

    &__sub {
        font-weight: bold;
        @include fs(18);
        display: block;
        line-height: 1;
        padding-top: 0.4em;
        letter-spacing: 1.8px;
        @include sp() {
            @include vw(27);
        }

    }
    // 会社情報ページ本文内タイトル
    &--S {

    }

}
