.m-navBtn{
	display: flex;
	justify-content: flex-start;
	@include sp() {
		justify-content: space-between;
		flex-wrap: wrap;
	}

	& > li{
		display: block;
		flex-grow: 1;
		flex-shrink: 1;
		line-height: 1;
		width: 100%;
		min-height: 43px;
		// max-width: 100px;
		& + li{
			@include pc() {
				margin-left: 12px;
			}
			@include sp() {
				margin-top: 12px;
			}
		}
		@include sp() {
			width:calc(50% - 6px);
			min-height: 120/750*100vw;
			max-width: calc(50% - 6px);
			flex-grow: 0;
			margin-top: 12px;

		}


		a{
			padding:10px 2px;
			display: block;
			background: #F7F7F7;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content:center;
			align-items:center;

			@include fs(12);
			line-height: 1.2;
			text-align: center;
			font-weight: bold;


			@include sp() {
				padding:10/750*100vw 10/750*100vw;
				@include vw(28);

			}

		}
		&:hover a,
		&.is-current a{
			background: #CF1721;
			color: #fff;
		}
	}



}
