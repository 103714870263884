.p-distList{
		border-bottom: 1px solid rgba($color: #707070, $alpha: .3);


	& > li{

		border-top: 1px solid rgba($color: #707070, $alpha: .3);

		padding: 1.6em 0;
		@include pc() {
			display: flex;
			justify-content: space-between;
			align-items:center;

		}

	}

	&__nameBox{
		flex-grow: 1;
		@include fs(14);
		@include sp() {
        	@include vw(28);
		}
	}
	&__linkBox{
		// flex-grow: 1;
		text-align: right;
		display: flex;
		justify-content: space-between;
		@include sp() {
			padding-top: 20/750*100vw;
			text-align: left;
			justify-content:flex-start;

		}
	}


	// 廃番
	&__stock{
		padding-right: 1em;
		font-size: inherit;
	}
	&__number{
		font-size: inherit;


	}
	&__ttl{
		padding-left: 1em;
		font-size: inherit;

	}
	&__maker{
		// padding-left: 1em;
		font-size: inherit;

	}
	&__ctg{
		padding-left: 1em;
		font-size: inherit;
	}
	&__tori,
	&__kani,
	&__set,
	&__prd{
		padding-right: 1.2em;
		@include sp() {
			padding-right: 1.2em;

		}
	}
	// &__tori,
	&__prd{
		@include sp() {
			padding-right:0;
		}
	}
	&__kani{
		@include sp() {
			display: none;
		}
	}


}

