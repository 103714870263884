.p-ptnList{

	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items:stretch;

	& > li{
		width: calc(50% - 8px);
		min-height: 220px;
		display: block;
		margin-top: 16px;

		background: #fff;
		padding: 46px 36px 26px  46px;
		display: flex;
		flex-direction: column;

		@include sp() {
			width: 100%;
			padding: 75/750*100vw 58/750*100vw 59/750*100vw ;

		}
		&:nth-child(1){
			margin-top: 0;
		}
		@include pc() {
			&:nth-child(2){
				margin-top: 0;
			}
		}

	}

	&__block{
		@include pc() {

			display: flex;
			flex-grow: 1;
			justify-content: flex-start;
		}



	}
	&__logoBox{
		width: 30%;
		flex-shrink: 0;
		@include sp() {
			display: block;
			width: 100%;

		}

		img{
			max-width: 80%;
			height: auto;
			display: block;
			margin:5px 0 0;
			@include sp() {
				width: 50%;
				margin:5px auto 0;

			}

		}
	}

	&__txtBox{
		@include pc() {
			flex-grow: 1;
			padding:0 53px 0 23px;
			border-left: 1px solid  rgba($color: #707070, $alpha: .3);
		}
		@include sp() {
			padding: 42/750*100vw 0 0 0;
			margin: 42/750*100vw 0 0 ;
			width: 100%;
			border-top: 1px solid  rgba($color: #707070, $alpha: .3);

		}
		p{
			@include fs(14);
			line-height: 1.8;
			@include sp() {
				@include vw(28);

			}
		}
	}
	&__linkList{
		padding-top: 2em;
		width: 100%;
		display: flex;
		justify-content: flex-end;
		@include sp() {
			padding-top: 4em;

		}

		& > li{
			display: inline-block;
			// padding:0 1em;
		    position: relative;
			line-height: 1;
			& + li{
				margin-left: 2em;
			}

			&::before {
				content:"";
				display: inline-block;
				width: 4px;
				height: 4px;
				border-top: 2px solid #CF1721;
				border-right: 2px solid #CF1721;
				position: absolute;
				top: 50%;
				right: 0;
				margin-right: 0;
				transform: translate(0, -50%) rotate(45deg);
				transition: margin 300ms 0s ease;

			}

			a:hover &::before,
			&:hover::before {
				margin-right: -5px;
			}

		}
		span{
			@include fs(12);
			line-height: 1;
			padding-right: 15px;
			@include sp() {
				@include vw(24);

			}
		}
	}
}
