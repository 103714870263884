.l-headerSchSP{
	@include sp() {

		padding: 74/750*100vw 0 0;
		display: block;

		&__innerBox{
			background: #fff;
			// display:flex;
			// align-items:center;
			padding:5px 10px;
			width: 100%;
			position: relative;
			height: 42px;
            // display: flex;
		}
		&__txtWrap{
			// flex-shrink: 1;
			// max-width: 50%;
		}
		&__ctgWrap{
// /			flex-grow: 1;
// 			flex-shrink: 0;
			border-left: 1px solid #CF1721;
			position: absolute;
			right: 36px;
			top:  5px;
			height: 32px;
            background: #fff;



            &::before {
                content:"";
                display: inline-block;
                width: 1px;
                height: 1px;
                border-top: 6px solid #CF1721;
                border-left:4px solid transparent;
                border-right:4px solid transparent;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }

		}
		option{
			width: 6em;
			height:12px;
			font-size: 10px;
			overflow: hidden;
			line-height: 1;
			text-overflow: ellipsis;
			white-space: nowrap;
			display: inline;
            appearance: none;


		}


		input[type=search],
		&__field{
			border: none;
			outline: none;
			padding:5px 4px;
			// font-size: 16px;
			font-size: 10px;

			line-height: 1;
			// max-width: 100%;
            appearance: none;
			height: 32px;
            display: block;
			width:calc( 100% - 6em ) ;

			// &:focus{
            //     // width: auto;
            // }


		}
		&__field--2{
			font-size: 10px;

		}


		&__ctg{
			border: none;
			padding:5px 0 5px 5px;
			font-size: 16px;
			font-size: 12px;
			appearance: none;

			line-height: 1;
			margin:0 0 0 2px;
			display: block;

			overflow: hidden;
			border: none;
			outline: none;
			line-height: 1;
			display: inline-block;
            appearance: none;

			// overflow: hidden;
			white-space: nowrap;
			// text-overflow: ellipsis;
            // display: inline;
            // width: 70px;
                // width: 5em;
            transition: width 300ms 0s ease;
            padding-right: 14px;
            width: 6em;
            height: 32px;



			&:focus{
				// background: #edeff2;
				border: none;
				outline: none;

                display: inline-block;
                // width: 14em;
                // width: auto;
			}
		}
		&__ctg--2{
			font-size: 10px;

		}
		&__btn{
			margin:0 0 0 2px;
			background:transparent ;
			border: none;
			cursor: pointer;
			padding: 10px 4px;
			width: 32px;
			position: absolute;
			right: 0;
			top:  0;
                appearance: none;

			img{
				width: 20px;
			}
		}
	}
}
