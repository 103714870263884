@charset "utf-8";
// /**
//  * define valiables
//  * 各scssファイルでよく使う値を変数定義
//  */

@font-face {
   font-family: 'Noto Sans JP';
   font-style: normal;
   font-weight: 400;
    src: local("Noto Sans CJK JP Regular"),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
    url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');

}
@import url('https://fonts.googleapis.com/css?family=Barlow:600,800&display=swap');




//ブレイクポイント
$breakPoint: 750;


//サイト全体のフォントカラー
$color_txt: #242424;

//リンクの設定
$color_lnk: #242424;//リンクのカラー
$link_deco:none;//リンクのデコレーション設定

//フォーム系
$color_key: #15a9d8;
$color_placeholder: #B4B4B4;
$color_bg: #fff;
$color_contents: #eeeceb;
$color_consultant: #d29b00;
$color_researcher: #1b4b7d;



$color_red: #E30A15;
$color_red2: #CF1721;


//baseのfont-size
$base_fs:15;


$imgPath:"/wp-content/themes/tenpo-planning/assets/img/";
$imgPath:"/wp/wp-content/themes/tenpo-planning/assets/img/";


$font-family-basic:
    "Noto Sans JP",
    "ヒラギノ角ゴ ProN W3",            // Japanese font for MAC OS X v10.5 or later
    "Hiragino Kaku Gothic ProN",
    "ヒラギノ角ゴ Pro W3",            // Japanese font for the previous MAC OS X v10.4
    "Hiragino Kaku Gothic Pro",
    "ヒラギノ丸ゴ ProN W4",            // Japanese font for MAC OS X v10.5 or later
    "Hiragino Maru Gothic ProN",
    "ヒラギノ丸ゴ Pro W4",            // Japanese font for the previous MAC OS X v10.4
    "Hiragino Maru Gothic Pro",
    "メイリオ",                        // Japanese font for Windows8/7/Vista
    "Meiryo",
    "ＭＳ Ｐゴシック",                // Japanese font for the previous WindowsXP
    "MS PGothic",
    Sans-Serif                        // others
;

$font-family-Barlow:
    'Barlow',
    "Noto Sans JP",
    "ヒラギノ角ゴ ProN W3",            // Japanese font for MAC OS X v10.5 or later
    "Hiragino Kaku Gothic ProN",
    "ヒラギノ角ゴ Pro W3",            // Japanese font for the previous MAC OS X v10.4
    "Hiragino Kaku Gothic Pro",
    "ヒラギノ丸ゴ ProN W4",            // Japanese font for MAC OS X v10.5 or later
    "Hiragino Maru Gothic ProN",
    "ヒラギノ丸ゴ Pro W4",            // Japanese font for the previous MAC OS X v10.4
    "Hiragino Maru Gothic Pro",
    "メイリオ",                        // Japanese font for Windows8/7/Vista
    "Meiryo",
    "ＭＳ Ｐゴシック",                // Japanese font for the previous WindowsXP
    "MS PGothic",
    Sans-Serif                        // others
;
