
.p-comOffice{
    &Map{
        display: block;
        text-align: center;
        padding: 76px 10px;
        @include sp() {
            padding: 150/750*100vw 40/750*100vw;

        }
    }
    &List{
        padding:30px 0 30px;
        display: flex;
        justify-content: space-between;
        flex-wrap:wrap;
        @include sp() {
            padding: 36/750*100vw 0;
            flex-direction: column;


        }
        & > li{
            background: #fff;
            padding:43px 37px 26px  43px;
            display: flex;
            width: calc(50% - 8px);
            align-items:stretch;
            margin-top: 17px;
            @include sp() {
                padding: 58/750*100vw;
                flex-direction: column;
                width: 100%;

            }
            @include pc() {

                &:nth-child(1),
                &:nth-child(2){
                    margin-top:0;
                }
            }
            & + li{
                @include sp() {
                    margin-top: 36/750*100vw;

                }
            }
            a{
                display: block;
                width: 100%;
            }
        }

        &__box{
            display: flex;
            flex-wrap:wrap;
			flex-direction: column;
			height: 100%;

        }
        &__ttl{
            padding:0 0 0 0;
            width: 130px;
            @include fs(16);
            font-weight: bold;
            letter-spacing: 3.4px;

            @include sp() {
                padding: 0 0 28/750*100vw 0;
                width: 100%;
                @include vw(28);
                letter-spacing: 5.6px;
                border-bottom: 1px solid rgba($color: #707070, $alpha: .23);

            }
            p{
                font-size: inherit;
            }
        }
        &__main{
 			width: calc(100% - 130px);
           flex-grow: 1;
           border-left: 1px solid rgba($color: #707070, $alpha: .23);
           padding: 0 0 0 23px;
           @include fs(14);

           @include sp() {
                @include vw(28);
                padding: 23/750*100vw  0 0 0;
                border-left:none;
                width: calc(100% - 150/750*100vw);
                align-self:flex-end;


           }
            p{
                font-size: inherit;
            }
        }
		&__inner{
            display: flex;
            flex-wrap:wrap;
			flex-grow: 1;
			padding-bottom: 1em;

		}
        // &__link{
        //     width: 100%;
        //     text-align: right;
        //     position: relative;
        //     line-height: 1;
        //     @include fs(12);
        //     &::before {
        //         content:"";
        //         display: inline-block;
        //         width: 4px;
        //         height: 4px;
        //         border-top: 2px solid #CF1721;
        //         border-right: 2px solid #CF1721;
        //         position: absolute;
        //         top: 50%;
        //         right: 0;
        //         margin-right: 0;
        //         transform: translate(0, -50%) rotate(45deg);
        //         transition: margin 300ms 0s ease;

        //         a:hover &{
        //             margin-right: -5px;
        //         }
        //     }
        //     @include sp() {
        //         @include vw(28);
        //         width: 150/750*100vw;
        //         flex-shrink: 0;
        //         height: 1em ;
        //         align-self:flex-end;

        //     }




        // }
        // &__linkTxt{
        //     line-height: 1;
        //     @include fs(12);
        //     letter-spacing: 0.7px;
        //     font-family: $font-family-Barlow;
        //     padding-right: 15px;
        //     font-weight: 600;
        //     @include sp() {
        //         @include vw(28);

        //     }

        // }
    }
    &Block{
        padding:100px 0;

        @include sp() {
            padding: 80/750*100vw 0 120/750*100vw;
        }
		@media print {
	        padding: 0;
		}
        &__inner{
            padding: 90px;
            background: #fff;
            @include sp() {
                padding: 90/750*100vw 40/750*100vw 100/750*100vw;
            }

        }
        &__wrap{
            display: flex;
            padding: 24px 0 40px;
            @include sp() {
                padding: 52/750*100vw 0 ;

            }

        }
        &__address{
            flex-grow: 1;
            p{
                @include fs(14);
                @include sp() {
                    @include vw(28);

                }
            }

        }
        &__print{
            align-self:flex-end;
			@media print {
				display: none;
			}
            @include sp() {
                display: none;
            }
			button{
				background: none;
				border: none;

			}
            span{
                position: relative;
                display: inline-block;
                font-family: $font-family-basic;

                &::before {
                    content:"";
                    display: inline-block;
                    width: 1em;
                    height: 1em;
                    position: absolute;
                    top: 50%;
                    right: 100%;
                    transform: translate(0, -50%);
                    background:transparent url(#{$imgPath}common/icon_print.svg) no-repeat 50% center;
                    background-size: contain;
                    margin-right: 10px;
                }
            }
        	&:hover{
                span{
                    cursor: pointer;
                    text-decoration: underline;
                }
            }
        }


        &__map{
            width: 910px;
            height: 390px;
            background: #ccc;
            iframe{
                width: 910px;
                height: 390px;
            }
            @include sp() {
                width: 100%;
                height: auto;
                width: 597/750*100vw;
                height: 390/750*100vw;
                iframe{
                    width: 597/750*100vw;
                    height: 390/750*100vw;
                }
            }

			// @media print {
			// 	width: 910px;
			// 	height: 600px;
			// 	iframe{
			// 		width: 910px;
			// 		height: 600px;
			// 	}
			// }

        }
    }
    &BlockTtl{
        &__sub{
            color: #CF1721;
            display: block;
            font-family: $font-family-Barlow;
            line-height: 1;
            @include fs(17);
            position: relative;
            padding-left: 35px;
            letter-spacing: 1.7px;

            @include sp() {
                @include vw(28);

            }
            &::before {
                content:"";
                display: inline-block;
                width: 28px;
                height: 2px;
                background: #CF1721;
                position: absolute;
                top: 50%;
                left: 0;
                margin-right: 1em;
                transform: translate(0, -50%);
                @include sp() {
                    height: 4px;


                }
            }
        }
        &__txt{
            font-weight: bold;
            @include fs(22);
            font-weight: bold;
            display: block;
            letter-spacing: 2.2px;
            @include sp() {
                @include vw(42);
                padding-top: 10px;

            }
        }
    }
    // 一覧リンク
    &Flink{
        display: flex;
        padding: 50px 0;
        justify-content: space-between;
        @include sp() {
            flex-wrap:wrap;
            padding: 45/750*100vw 0;

        }
        & > li{
            // padding-right: 30px;

            @include sp() {
                padding: 0 0 1em ;
                width: 40%;
                &:nth-child(even){
                    width: 60%;
                }
            }
        }
        &__txt{
            @include fs(14);
            letter-spacing: 0.7px;
            padding-right: 15px;
            font-weight: bold;

            position: relative;
            .is-current &{
                color: #CF1721;
            }

            &::before {
                content:"";
                display: inline-block;
                width: 5px;
                height: 5px;
                border-top: 2px solid #CF1721;
                border-right: 2px solid #CF1721;
                position: absolute;
                top: 50%;
                right: 0;
                margin-right: 0;
                transform: translate(0, -50%) rotate(45deg);
                transition: margin 300ms 0s ease;

                a:hover &{
                    margin-right: -5px;
                }
            }


            @include sp() {
                @include vw(28);

            }
        }

    }

}

