.p-news{
	&__head{
		border-bottom: 1px solid rgba($color: #707070, $alpha: .3);
		padding-bottom: 8px;
	}
	&__headBox{

	}
	&__date{
            color: #242424;
            @include fs(14);
            flex-shrink: 0;
            display: inline-block;
            line-height: 1.4;
            @include sp() {
                @include vw(24);
            }
	}
	&__ctg{
            margin:0 20px;
            background: #242424;
            color: #fff;
            @include fs(11);
            display: inline-flex;
            justify-content:center;
            align-items:center;

            line-height: 1;
            padding:6px 10px;
			width: 10em;

            @include sp() {
                @include vw(20);
            }
	}

	&__ttlWrap{
		padding:22px 0 30px;
		@include sp() {
			padding:22/750*100vw 0 30/750*100vw;
		}

	}
	&__ttl{
		font-weight: bold;
		@include fs(22);
		@include sp() {
			@include vw(28);
		}
	}
	&__print{
		text-align: right;
		line-height: 1;
		a{
			display: inline-block;
			&:hover{
				text-decoration: underline;
			}
		}
	}
	&__body{
		// padding: 50px 0 90px;
		padding: 0 0 90px;
		@include sp() {

			// padding: 50/750*100vw 0 90/750*100vw;
			padding: 0 0 90/750*100vw;
		}
		& > p{
			padding-bottom: 0.8em;
			line-height: 2;
		}
		& > table{
			// width: 100%;
			// margin-top: 2em;
				@include sp() {
					table-layout: fixed;
				}

		}

        th{
            font-weight: bold;
            padding: 0 2em 1em 0;
            vertical-align: top;
            word-break: break-all;
            @include sp() {
                word-break:keep-all;
            }

        }
        td{
            padding: 0 2em 1em 0;
            vertical-align: top;
            word-break: break-all;
        }
        a{
            &:hover{
                text-decoration: underline;
            }
        }
	}
	&__inDetails{
		padding-top: 150px;
		@include sp() {
			padding-top: 150/750*100vw;
		}
		& + &{
			padding-top: 200px;
			@include sp() {
				padding-top: 150/750*100vw;
			}
		}
	}


}
