@charset "utf-8";



    // 新着情報
.p-topNews{
    background: #242424;
    color: #fff;
    padding: 90px 20px 74px;
    // min-width: 1080px;
    min-width: 1120px;


    @include sp() {
        min-width:auto;
        padding: 120/750*100vw 0 120/750*100vw;
    }
    a{
        color: #fff;
    }
    &__inner{
        max-width: 1080px;
        min-width: 1080px;
        margin: auto;
        @include sp() {
            min-width: auto;
            width: 680/750*100vw;
        }
    }
    &__link{
        width: 100%;
        text-align: right;
        position: relative;
        line-height: 1;
        @include fs(12);
        &::before {
            content:"";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-top: 2px solid #CF1721;
            border-right: 2px solid #CF1721;
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 0;
            transform: translate(0, -50%) rotate(45deg);
            transition: margin 300ms 0s ease;
        }
        &:hover::before {
            margin-right: -5px;
        }

        @include sp() {
            @include vw(28);
            // width: 150/750*100vw;
            flex-shrink: 0;
            height: 1em ;
            align-self:flex-end;

        }

    }
    &__linkTxt{
        line-height: 1;
        @include fs(12);
        letter-spacing: 0.7px;
        font-family: $font-family-Barlow;
        padding-right: 15px;
        font-weight: 600;
        @include sp() {
            @include vw(28);

        }
    }

    &Block{
        padding: 64px 0 10px;
        margin-top: 32px;
        position: relative;
        @include sp() {
            margin-top: 40/750*100vw;
        }
        &__ctg{

            display: block;

        }
        &__tab{
            display: block;
            width: 33.2%;
            width: calc( 33.33% - 1px );
            background: #404040;
            color: #fff;
            line-height: 1;
            height: 64px;
            position: absolute;
            display: flex;
            justify-content:center;
            align-items:center;
            top: 0;
            @include sp() {
                width: calc( 33.33% - 2px );
                span{
                    max-width: 4.5em;
                    display: inline-block;
                    text-align: center;
                }

            }

            .p-topNewsBlock__ctg:nth-child(1) &{
                left: 0;
            }
            .p-topNewsBlock__ctg:nth-child(2) &{
                left: 0;
                right: 0;
                margin: auto;
            }
            .p-topNewsBlock__ctg:nth-child(3) &{
                right: 0;
            }
            .p-topNewsBlock__ctg.is-current &{
                background: #CF1721;
            }
            &:hover{
                background: #CF1721;
                cursor: pointer;
            }
        }
        &__body{
            background: #fff;
            color: #242424;
            display: none;
            .p-topNewsBlock__ctg.is-current &{
                display: block;
            }
            a{
                color: #242424;
            }

        }

    }


}
