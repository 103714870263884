
.p-comOem{
	&List{
		background: #fff;
		display: flex;
		padding: 51px 0 44px;
		display: flex;
		justify-content: space-between;
		margin-top: 40px;
		@include sp() {
			flex-direction: column;
			padding: 0;

		}
		& > li{
			@include pc() {
				width: calc(33%);
				& + li{
					border-left: 1px solid #EDEFF2;
				}
			}
			@include sp() {
				width: 593/750*100vw;
				margin: auto;
				padding:90/750*100vw 0;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;

				& + li{
					border-top: 1px solid #EDEFF2;
				}

			}
		}
		&__ttl{
			font-family: $font-family-Barlow;
			color: #CF1721;
			@include fs(15);
			display: block;
			line-height: 1;
			letter-spacing: 1.5px;
			font-weight: 800;
			text-align: center;

			@include sp() {
				@include vw(28);
				width: 100%;
				padding-bottom: 30/750*100vw;
			}
		}
		&__imgWrap{
			display: block;
			height: 207px;
			display: flex;
			justify-content:center;
			align-items:center;
			@include sp() {
				width: 45%;
				height: auto;

			}
		}
		&__img{
			display: block;
			margin: auto;
			&--short{
				width: 136px;
				padding-top: 20px;
				@include sp() {
					width: 219/750*100vw;
					padding:40/750*100vw 0 10/750*100vw;
				}
			}
			&--no{
				width: 128px;
				padding-top: 10px;

				@include sp() {
					width: 204/750*100vw;
					padding: 0;
				}
			}
			&--app{
				width: 141px;
				@include sp() {
					width: 225/750*100vw;
					padding: 0;
				}
			}
		}
		&__txt{
			text-align: center;
			font-weight: bold;
			@include fs(20);
			line-height: 1.4;
			@include sp() {
				@include vw(28);
				text-align: left;
				display: flex;
				justify-content:flex-start;
				align-items:center;
				width: 45%;
				letter-spacing: .1em;
			}

		}

	}

	// 短期間、低コストで「顧客満足度が高い」商品が出来上がります。
	&Note{
		background: #CF1721;
		text-align: center;
		color: #fff;
		padding: 1em;
		@include sp() {
			padding: 1.6em 0;


		}
		&__txt{
			font-weight: bold;
			@include fs(22);
			@include sp() {
				@include vw(34);


			}

		}
	}

}
