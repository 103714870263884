@charset "utf-8";
.l-headerNavSP{
	@include sp() {
		width: 100%;




		& > li {
			display: block;
			padding-top: 40/750*100vw;

			&.l-headerNavSP__serch{
				display: none;
			}

		}
		a{
			// height: 72px;
			position: relative;
			font-weight: bold;
			cursor: pointer;
			color: #fff;
			display: block;
			@include vw(30);
			padding:10px 0  10px 36px;

			&::before {
				content:"";
				display: inline-block;
				width: 23px;
				height: 1px;
				position: absolute;
				background: #fff;
				top: 50%;
				left: 0;
				}


		}
	}
}
