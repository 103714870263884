.l-footer {

    &Copy{
        width: 100%;

        display: block;
        margin: auto;
        padding: 22px 0;
        line-height: 1;
        @include pc() {
            max-width: 1266px;
            // min-width: 1080px;
            min-width: 1120px;
            padding: 22px 20px;
            display: flex;
            justify-content: flex-end;
        }

        @include sp() {
            text-align: center;
        }
        & > p{

            @include fs(11);
            line-height: 1;
            @include pc() {

                border-right: 1px solid #fff;
                padding-right: 4em;
                margin-right: 4em ;
            }
            @include sp() {
                @include vw(20);
                padding-bottom: 1em;
            }

        }

        & > small{
            @include fs(11);
            line-height: 1;
            @include sp() {
                @include vw(20);
            }

        }
    }
}
