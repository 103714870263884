.m-txt{
	@include fs(14);
	line-height: 1.8;
	color: #242424;
	@include sp() {
		@include vw(28);

	}

}
