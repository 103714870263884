.p-cntTxt{
	text-align: center;
	@include sp() {
		text-align: left;

	}
}
.p-cntTxt-S{
	text-align: center;
		@include fs(12);

	@include sp() {
		text-align: left;
			@include vw(24);

	}


}

.p-cnt{
		border-top: 1px solid rgba($color: #707070, $alpha: .2);
		margin-top: 50px;
		// padding-top: 60px;
		padding-top: 30px;

	&__item{
		padding: 10px 0;
		display: flex;
		@include sp() {
			display: block;
			width: 100%;
			padding: 30px 0;

		}

	}
	&__item2{
		padding: 10px 0;
		display: block;
		@include sp() {
			display: block;
			width: 100%;
			padding: 30px 0;

		}

	}
	&__ttlBox{
		width: 200px;
		@include sp() {
			width: 100%;

		}
	}
	&__ttlBox2{
		width: 100%;

		@include sp() {
			width: 100%;

		}
	}
	&__dataBox{
		width: calc(100% - 200px);
		display: block;
		padding: 0 0 0 40px;
		@include sp() {
			padding: 20px 0 0 0 ;
			width: 100%;

		}
	}
	&__dataBox2{
		width: 100%;

		display: block;
		padding:  20px 0 0 0;
		@include sp() {
			padding: 20px 0 0 0 ;
			width: 100%;

		}
	}




	&__label{
		display: inline-flex;
		justify-content: space-between;
		width: 100%;
		align-items:center;

	}
	&__ttl{
		@include fs(12);
		line-height: 1;
		display: inline-block;
		vertical-align: middle;
		@include sp() {
			@include vw(28);
			font-weight: bold;
		}

	}
	&__tag{
		color: #CF1721;
		border: 1px solid #CF1721;
		line-height: 1;
		padding:2px 1em 2px 2em;
		@include fs(10);
		display: inline-block;
		vertical-align: middle;
		letter-spacing: 1em;
		@include sp() {
			@include vw(20);
		}

	}
	&__data{

	}
	&__outputDice{
		.row,
		.col-sm-3{
			display: inline-block;
		}

		input[type=text]{
			background: #F7F7F7 ;
			border: 1px solid #EAEAEA;
			margin-left: 1em;

			@include sp() {
				font-size: 16px;
				appearance: none;

			}
		}
	}
	@at-root{

		input[type=password]#{&}__inputTxt,
		input[type=text]#{&}__inputTxt{
			background: #F7F7F7 ;
			border: 1px solid #EAEAEA;
			@include sp() {
				font-size: 16px;
				appearance: none;

			}

			&::placeholder{
				color: #B4B4B4;
			}
			.has-error &{
				border: 1px solid #CF1721;
			}

			&--company{
				width: 100%;
			}
			&--name{
				width: 100%;
			}
			&--pass{
				width: 50%;
				@include sp() {
					width: 100%;
				}
			}
			&--zip{
				width: 50%;
				@include sp() {
					width: 100%;
				}

			}
			&--mail{
				width: 100%;
			}
			&--mail2{
				width: 100%;
				max-width: 600px;
			}
			&--tel{
				width: 100%;
			}
		}
		select#{&}__select{
			background: #F7F7F7 ;
			border: 1px solid #EAEAEA;
			@include sp() {
					font-size: 16px;
					appearance: none;

			}

			.has-error &{
				border: 1px solid #CF1721;
			}

			&--biz{
				width: 100%;
			}
			&--area{
				width: 50%;
				@include sp() {
					width: 100%;
				}

			}
		}


		textarea#{&}__textarea{
				background: #F7F7F7 ;
				border: 1px solid #EAEAEA;
				@include sp() {
					font-size: 16px;
					appearance: none;
				}

				.has-error &{
					border: 1px solid #CF1721;
				}


				&--add{
					width: 100%;
				}
				&--msg{
					width: 100%;

				}

		}
	}
	&__btnBlock{
		border-top: 1px solid rgba($color: #000000, $alpha: .1);
		margin-top: 60px;
		padding-top: 70px;

	}
	&__btn{
		display: block;
		width: 341px;
		background: #747474;
		color: #fff;
		border: none;
		// font-weight: bold;
		font-family: $font-family-basic;
		line-height: 1;
		cursor: pointer;
		margin: 10px auto;
		padding: 20px;
		&:hover{
			opacity: 1;
			background: $color_red;

		}



	}
	&__note{
		@include fs(12);
		@include sp() {
			@include vw(24);
		}

	}
	&__att{
		@include fs(12);
		color: #CF1721;
		@include sp() {
			@include vw(24);
		}
	}
	&__err{
		@include fs(12);
		color: #CF1721;
		@include sp() {
			@include vw(24);
		}
	}
	.alert-warning{
		color: #CF1721;

	}
}
