@charset "utf-8";

.l-footer {
    background: #242424;
    position: relative;
    color: #fff;
    // min-width: 1080px;

    min-width: 1120px;


    @include sp() {
        min-width:auto;
        padding: 0;
    }
    a{
        color: #fff;
        &:hover{
            text-decoration: underline;
        }

    }
    @media print {
        display: none;
    }
    &__pagetop {
        position: absolute;
        bottom: 100%;
        right: 0;
        @include sp() {
            display: none;
        }
    }

    &__inner {
        width: 100%;
        max-width: 1266px;
        min-width: 1080px;
        // min-width: 1266px;
        display: block;
        margin: auto;
        display: flex;
        padding: 63px 0 55px;
        @include sp() {
            min-width: auto;
        }

    }
    &__copy{
        background: #313131;
    }

    &__linkWrap {
        display: flex;
        justify-content: space-between;
        flex-grow: 1;
        @include pc() {
            padding-right: 92px;

        }
        @include sp() {
            display: none;

        }

    }




}
