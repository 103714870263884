@charset "utf-8";

    // パートナーシップ提携企業
.p-topPtn{
    background: #EDEFF2;
    padding: 100px 20px 120px;
    // min-width: 1080px;
    min-width: 1120px;
    @include sp() {
        min-width:auto;
        padding: 100px 0 120px;

    }

    &__inner{
        max-width: 1080px;
        min-width: 1080px;
        margin: auto;
        @include sp() {
            min-width: auto;
            width: 680/750*100vw;
        }
    }
    &__list{
        padding: 32px 0 10px;
        display: flex;
        flex-wrap: wrap;
        @include sp() {
            padding: 32/750*100vw 0 10/750*100vw;

        }
        & > li{
            display: block;
            width: 344px;
            height: 125px;
            margin: 24px 24px 0 0;
			background: #fff;
            @include sp() {
                width: 100%;
                height: auto;
                margin: 9px 0 0;
            }
            &:hover{
                box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .1);
            }
            @include pc() {
                &:nth-child(3n){
                    margin: 24px 0 0;
                }
            }
        }
        img{
            max-width: 100%;
            display: block;
			margin: auto;
        }
        a{
            display: block;
			height: 100%;
			width: 100%;
            &:hover{
                opacity: 1;
            }
        }

    }
    &__link{
        width: 100%;
        text-align: right;
        position: relative;
        line-height: 1;
        @include fs(12);
        &::before {
            content:"";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-top: 2px solid #CF1721;
            border-right: 2px solid #CF1721;
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 0;
            transform: translate(0, -50%) rotate(45deg);
            transition: margin 300ms 0s ease;
        }
        &:hover::before {
            margin-right: -5px;
        }

        @include sp() {
            @include vw(28);
            // width: 150/750*100vw;
            flex-shrink: 0;
            height: 1em ;
            align-self:flex-end;

        }

    }
    &__linkTxt{
        line-height: 1;
        @include fs(12);
        letter-spacing: 0.7px;
        font-family: $font-family-Barlow;
        padding-right: 15px;
        font-weight: 600;
        @include sp() {
            @include vw(28);

        }
    }

}


