.p-sptFooterList{
	display: flex;
	justify-content: space-between;
	padding: 60px 0;
	width: 855px;
	margin: auto;
	@include sp() {
		width: 100%;
		padding: 80/750*100vw 0;

	}
	&--in{
		padding:0 0 60px 0;
		@include sp() {
			padding:0 0 80/750*100vw 0;
		}

	}

	& > li{
		padding: 1em 0;
		flex-grow: 1;
		width: 100%;
		text-align: center;
		& + li{
			@include pc() {
				border-left: 1px solid  rgba($color: #707070, $alpha: .3);
			}

		}

	}
	a{
		display: block;
		&:hover{
			opacity: .7;
		}
	}
	&__icon{
		display: block;
		margin: auto;
		width: 50px;
		height: 40px;
		@include sp() {
			width: 80/750*100vw;
			height: 80/750*100vw;


		}

		&--faq{
			background:transparent url(#{$imgPath}common/icon_s_faq.svg) no-repeat 50% center;
			background-size: 48px 38px;
			@include sp() {
				background-size: contain;
			}
		}
		&--soft{
			background:transparent url(#{$imgPath}common/icon_s_soft.svg) no-repeat 50% center;
			background-size: 35px 30px;
			@include sp() {
				background-size: contain;
			}
		}
		&--man{
			background:transparent url(#{$imgPath}common/icon_s_man.svg) no-repeat 50% center;
			background-size: 30px 37px;
			@include sp() {
				background-size: contain;
			}		}
		&--con{
			background:transparent url(#{$imgPath}common/icon_s_con.svg) no-repeat 50% center;
			background-size: 34px 22px;
			@include sp() {
				background-size: contain;
			}		}
	}
	span{
		@include fs(14);
		color: #747474;
		font-family: $font-family-Barlow;
		padding-top: .5em;
		display: inline-block;
		// font-weight: bold;
		@include sp() {
			@include vw(21);

		}

	}
}
