.m-pnav {

	display: block;
	background: #fff;
	width: 1080px;
	min-height: 66px;
	box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .1);
	margin: auto;
	// margin-top: -30px;
	transform: translate(0, -50%);
	display: flex;
	justify-content: space-between;
	align-items: center;

	@include sp() {
		width: 678/750*100vw;
		justify-content: flex-start;
		flex-wrap: wrap;
		transform: translate(0, -9vw);
		align-items:flex-end;

	}

	&>li {
		flex-grow: 1;
		text-align: center;
		padding: 1em;
		@include sp() {
			padding: 1em .5em;

			width: 33.33%;
			flex-grow: 0;


		}
	}

	&__link {
		position: relative;
		display: block;

		span{
			padding-right: 15px;
			font-weight: bold;
			@include fs(12);
			display: inline-block;
			word-break: keep-all;
			@include sp() {
				padding-right: 0;
				padding-bottom: 10px;
				@include vw(26);
				line-height: 1.2;
				word-break: break-all;
				overflow-wrap: break-word;



			}
		}

		&::after {
			content: "";
			display: inline-block;
			width: 4px;
			height: 4px;
			border-top: 2px solid #CF1721;
			border-right: 2px solid #CF1721;
			position: absolute;
			top: 50%;
			// right: 0;
			transform: translate(0, -50%) rotate(135deg);
			@include sp() {
				@include vw(28);
				top: auto;
				bottom: 0;
				left: 50%;
				transform: translate(-50% , 0 ) rotate(135deg);


			}

		}



	}
}
