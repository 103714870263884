.p-manList{

	// & > li{
	&__item{
		padding: 1.6em 0;
		@include pc() {
			display: flex;
			justify-content: space-between;
			align-items:center;

		}
		@include sp() {
			&:first-child{
				padding: 0 0 1.6em ;
			}

		}
		& + &{
			border-top: 1px solid rgba($color: #707070, $alpha: .3);
		}
	}

	&__nameBox{
		flex-grow: 1;
		@include fs(14);
		@include sp() {
        	@include vw(28);
		}
	}
	&__linkBox{
		// flex-grow: 1;
		text-align: right;
		display: flex;
		justify-content: space-between;
		@include sp() {
			padding-top: 20/750*100vw;
		}
	}


	// 廃番
	&__stock{
		// padding-right: 1em;
		font-size: 90%;
		border: 1px solid #747474;
		color: #747474;
		line-height: 1;
		padding:3px 10px;
		vertical-align: middle;
		margin-right: .5em;
		display: inline-block;
	}
	&__number{
		font-size: inherit;


	}
	&__ttl{
		padding-left: 1em;
		font-size: inherit;

	}
	&__maker{
		// padding-left: 1em;
		font-size: inherit;

	}
	&__ctg{
		padding-left: 1em;
		font-size: inherit;
	}
	&__tori,
	&__kani,
	&__prd{
		padding-right: 1.2em;
		@include sp() {
			padding-right: .2em;

		}
	}
	&__prd{
		@include sp() {
			padding-right:0;

		}
	}

}
