.l-breadcrumbs{
    position: relative;
    width: 100%;
    z-index: 1;
    @include sp() {
        display: none;

    }
    &__item{
        position: absolute;
        display: inline-block;
        top: 15px;
        right: 0;
        @include fs(10);
        color: #747474;
        span{
            @include fs(10);
            color: #747474;

        }
        a{
            @include fs(10);
            color: #747474;
            &:hover{
                text-decoration: underline;
            }
        }
    }

}
