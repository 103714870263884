.m-logoList{
	padding: 32px 0 10px;
	display: flex;
	flex-wrap: wrap;
	@include sp() {
		padding: 32/750*100vw 0 10/750*100vw;

	}
	& > li{
		display: block;
		width: 344px;
		height: 125px;
		margin: 24px 24px 0 0;
		background: #fff;
        position: relative;

		@include sp() {
			width: 100%;
			height: auto;
			margin: 9px 0 0;
		}
		&:hover{
			box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .1);
		}
		@include pc() {
			&:nth-child(3n){
				margin: 24px 0 0;
			}
		}
	}
	img{
		// max-width: 50%;
		max-width: 172px;

		max-height: 70%;
		display: inline-block;
		margin: auto 0;

		@include sp() {
			max-height: 100%;

		}
	}
	a{
		display: block;
		height: 100%;
		width: 100%;
		display: flex;
		justify-content:center;
		align-items:center;
		&:hover{
			opacity: 1;
		}
		@include sp() {
			padding: 50/750*100vw 0;
			max-height: auto;

		}
	}

}
