

.m-titleB{
        &__sub{
            color: #CF1721;
            display: block;
            font-family: $font-family-Barlow;
            line-height: 1;
            @include fs(17);
            position: relative;
            padding-left: 35px;
            letter-spacing: 1.7px;

            @include sp() {
                @include vw(28);

            }
            &::before {
                content:"";
                display: inline-block;
                width: 28px;
                height: 2px;
                // background: #CF1721;
				border-top: 2px solid #CF1721;
                position: absolute;
                top: 50%;
                left: 0;
                margin-right: 1em;
                // transform: translate(0, -50%);
                @include sp() {
                    height: 4px;


                }
            }
        }
        &__txt{
            font-weight: bold;
            @include fs(22);
            display: block;
            letter-spacing: 2.2px;
            @include sp() {
                @include vw(42);
                padding-top: 10px;

            }
        }
}
