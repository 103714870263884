@charset "utf-8";
.l-main{
	&__inner{
		width: 100%;
		max-width: 1120px;
		min-width: 1120px;

        padding:0 20px;

		display: block;
		margin: auto;
        @include sp() {
            width: 680/750*100vw;
			min-width: auto;
            padding: 0;
        }
	}
	&__inner-L{
		width: 100%;
        max-width: 1266px;
		// min-width: 1266px;
        min-width: 1080px;

		display: block;
		margin: auto;
        @include sp() {
            width: 680/750*100vw;
			min-width: auto;
        }
	}



    &__bg-GY{
        background: #EDEFF2;
		min-width: 1120px;
        @include sp() {
          	min-width: auto;
        }
    }
    &__bg-BK{
        background: #242424;
		min-width: 1120px;
        @include sp() {
          	min-width: auto;
        }

    }
    &__bg-WH{
        background: #fff;
		min-width: 1120px;
        @include sp() {
          	min-width: auto;
        }

    }



    &__block{
        padding: 100px 0;
        @include sp() {
            padding: 80/750*100vw 0 120/750*100vw;
        }
    }
    &__box{
        padding: 90px;
        background: #fff;
        @include sp() {
            padding: 90/750*100vw 40/750*100vw 100/750*100vw;
        }
    }
    &__blank-L{
        height: 100px;
        display: block;
        @include sp() {
            height: 120/750*100vw;

        }
    }
    &__blank-M{
        height: 50px;
        display: block;
        @include sp() {
            height: 60/750*100vw;

        }
    }
    &__blank-S{
        height: 16px;
        display: block;
        @include sp() {
            height: 20/750*100vw;

        }
    }
    &__BgBoxWrap{
        //  要素カウント用
        display: block;
    }
    &__BgBox{
        padding:0 90px 50px;
        background: #fff;
        @include sp() {
            padding: 0 40/750*100vw 100/750*100vw;
        }
        &:first-child{
            padding-top: 50px;
            @include sp() {
                padding-top: 90/750*100vw;
            }

        }
        &:last-child{
            padding-bottom: 90px;
            @include sp() {
                padding-top: 90/750*100vw;
            }

        }
    }

}
