@charset "utf-8";
.u{
    &-mt00{
        margin-top: 0;
    }
    &-pt00{
        padding-top: 0;
    }
    &-pb00{
        padding-bottom: 0;
    }
    &-pci{
        display:inline-block;
        @include sp{
            display:none;
        }
    }
    &-spi{
        display:none;
        @include sp{
            display:inline-block;
        }
    }
    &-pc{
        display:block;
        @include sp{
            display:none;
        }
    }
    &-sp{
        display:none;
        @include sp{
            display:block;
        }
    }
	&-textC{
		text-align: center;
	}
	&-textL{
		text-align: left;
	}
	&-textR{
		text-align: right;
	}
	&-noPrint{
		@media print {
			display: none;
		}
	}
	&-txtRed{
		color: $color_red2;
		font-size: inherit;
	}
	&-txtBold{
		font-weight: bold;

	}
    &-w100p{
        width: 100%;
    }



}
