.p-demoList{
	display: flex;
	flex-wrap: wrap;
	& > li{
		width: 274px;
		margin: 36px 0 0 36px;
		&:nth-child(3n+1){
			margin: 36px 0 0 0;
		}
		@include sp() {
			width: 100%;
			margin: 80/750*100vw 0 0 0;
			&:nth-child(3n+1){
				margin: 80/750*100vw 0 0 0;
			}
		}
	}
	a{
		position: relative;
		display: block;

	}
	&__img{
		width: 100%;
		display: block;
		position: relative;
		&::before {
			content:"";
			display: inline-block;
			width:36px;
			height:36px;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			// background: #00f;
			background:transparent url(#{$imgPath}common/icon_play.svg) no-repeat 50% center;
			background-size: contain;
			z-index: 2;
		}
		&::after{
			content:"";
			display: inline-block;
			width:100%;
			height:100%;
			position: absolute;
			top: 0;
			left: 0;
			background: #000;
			opacity: .4;
			z-index: 1;

			a:hover &{
				opacity: 0;
			}
		}
		img{
			display: block;
			width: 100%;

		}
	}
	&__ttl{
		padding-top: 10px;
		@include fs(14);
		@include sp() {
			@include vw(28);
		}

	}

}
