.l-headerSnsSP{
	@include pc() {
		display: none;
	}
	@include sp() {
		display: block;

		padding: 74/750*100vw 0 0;
		li{
			display: inline-block;
			width: 30px;
			& + li{
				margin-left: 44/750*100vw;
			}
		}
	}

}
