@charset "utf-8";

// ダウンロード
.p-topDl{
    background: #EDEFF2;
    padding: 100px 0;
    min-width: 1080px;
    @include sp() {
        min-width:auto;
        padding: 80/750*100vw 0;

    }
    &__inner{
        max-width: 1080px;
        min-width: 1080px;
        margin: auto;
        @include sp() {
            width: 680/750*100vw;
            min-width: auto;

        }
    }
    &__list{
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;

        }
    }
    &__item{
        width: 532px;
        height: 248px;
        @include sp() {
            width: 100%;
            height: 316/750*100vw;
        }

        &--man{
            background: #fff;
            color: #242424;
            // background:transparent url(#{$imgPath}top/bg_dl_manual.png) no-repeat 50% center;
        &:hover{
            box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .1);
        }
            // @include sp() {
            //     background:transparent url(#{$imgPath}top/bg_dl_manual_sp.png) no-repeat 50%;
            //     background-size: contain;
            // }
        }
        &--soft{
            background: #000;
            color: #fff;
            // background:transparent url(#{$imgPath}top/bg_dl_soft.png) no-repeat 50% center;
            &:hover{
                box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .2);
            }
            @include sp() {
                margin-top: 15px;

            }
        }
    }
    a{
        display: block;
        width: 100%;
        height: 100%;
        // padding: 24px;
        // @include sp() {
        //     padding: 40/750*100vw;
        // }
        &:hover{
            opacity: 1;
        }
    }
    &__box{
        display: block;
        position: relative;
    }
    &__img{
        display: block;
    }
    &__ttl{
        position: absolute;
        top: 0;
        left: 0;
        padding: 24px;
        @include sp() {
            padding: 40/750*100vw;
        }

    }

    &__sub{
        color: #CF1721;
        display: block;
        font-family: $font-family-Barlow;
        line-height: 1;
        font-weight: 800;
        @include fs(32);
        @include sp() {
            @include vw(42);

        }
    }
    &__txt{
        .p-topDl__item--soft &{
            color: #fff;

        }
        font-weight: bold;
        @include fs(16);
        @include sp() {
            @include vw(26);

        }
    }

}
