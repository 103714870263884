.p-prdList{
	padding-top: 15px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
    &__item{
        margin-top: 15px;
		position: relative;
		background: #fff;


        &--analoghd,
        &--analog,
        &--monitor,
        &--exit{
            color: #fff;
            a{
                color: #fff;

            }
        }
        @include sp() {
            &--hdsdi,
            &--net,
            &--acces{
                text-align: right;

            }

        }

    }


    &__subListWrap{
		position: relative;
        display: block;
        height: 330px;
        width: 100%;
		@include sp() {
			height: auto;

		}

    }
    &__subList{
        // display: none;
        padding:  48px;
		display: flex;
		flex-direction: column;
		align-items:flex-start;
		@include sp() {
	        padding: 68/750*100vw 54/750*100vw;

		}
    }

    &__link{
        position: relative;
        line-height: 1;
        @include fs(14);
        display: inline-block;
        & + & {
            margin-top: 1.4em;
        }
        &::before {
            content:"";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-top: 2px solid #CF1721;
            border-right: 2px solid #CF1721;
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 0;
            transform: translate(0, -50%) rotate(45deg);
            transition: margin 300ms 0s ease;
        }
        &:hover::before {
            margin-right: -5px;
        }
    }
    &__linkTxt{
        line-height: 1;
        @include fs(14);
        font-weight: bold;
        padding-right: 15px;
        color: #242424;
    }
    &__box{
        display: block;
        position: relative;
    }
    &__box a{
		position: relative;
		display: block;

	}
    &__img{
        display: block;
        background: #dbe0e6;


		@include pc() {
			width: 350px;
			height: 280px;
		}
        @include sp() {
            display: block;
            width: 100%;
            source{
                display: block;
                width: 100%;

            }
        }
    }

    &__ttl{
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 24px;
        @include sp() {
            padding: 34/750*100vw 25/750*100vw;
			text-align: left;


            // .p-topPrd__item--hdsdi &,
            // .p-topPrd__item--net &,
            // .p-topPrd__item--acces &{
            //     right: 0;
            //     left: auto;
            // }



        }

    }

    &__sub{
        color: #CF1721;
        display: block;
        font-family: $font-family-Barlow;
        line-height: 1;
        font-weight: 800;
        @include fs(28);
        @include pc() {
            .p-topPrd__item--net &,
            .p-topPrd__item--monitor &,
            .p-topPrd__item--exit &,
            .p-topPrd__item--acces &{
                @include fs(26);
            }
        }


        @include sp() {
            @include vw(34);

        }
    }
    &__txt{
        font-weight: bold;
        color: inherit;
        @include fs(16);
        @include pc() {
            .p-topPrd__item--net &,
            .p-topPrd__item--monitor &,
            .p-topPrd__item--exit &,
            .p-topPrd__item--acces &{
                @include fs(14);
            }
        }
        @include sp() {
            @include vw(24);

        }
    }



}
