@charset "utf-8";
.l-headerLogin{
    // display: none;
    // height: 100%;
    display: block;
    height: 72px;
    @include pc() {

    position: relative;
    }
    @include sp() {
        // display: none;
        @include vw(20);
        width:7em;
        height: 100%;

    }
    &__btn{
        display: inline-flex;
        cursor: pointer;
        height: 100%;
        background: $color_red;
        color: #fff;
        padding:0 30px;
        justify-content:center;
        align-items:center;
        font-weight: bold;
        @include sp() {
           padding:0 10px;
           text-align: center;
       }
        span{
            @include sp() {
                @include vw(20);
                display: inline-block;
                width: 5em;
            }
        }


    }
    &__form{
        display: none;
        position: absolute;
        top: 100%;
        right: 0;
        // background: #fff;
        // border: 1px solid #000;
        background: #242424;
        padding: 30px;
        width: 310px;
        color: #fff;
        @include sp() {
            width: 100vw;
            padding:30px 23px;

        }


		&::before {
			content:"";
			display: block;
			width:14px;
			height:14px;
        	background: $color_red;
			position: absolute;
			top: 0;
			right: 78px;
			transform: translate(0, -50%) rotate(45deg);
            @include sp() {
                @include vw(20);
    			right: calc( 60px + 3em );

            }
		}
        &.is-open{
            display: block;
        }
        a{
            color: #fff;
			line-height: 1;
        }
    }
	&__inputWrap{
        @include sp() {
            display: flex;
            justify-content: space-between;

        }
    }
	&__input{
		margin-top: 10px;
		width: 100%;
        @include sp() {
            // @include vw(28);
            width: 49%;
            width: calc(50% - 5px);
            @include vw(32);
        }

	}
	&__submit{
		width: 100%;
		margin-top: 10px;
		cursor: pointer;
		padding: 10px;
		// height: 40px;
		line-height: 1;
		color: #fff;
		// top: 212px;
		background: $color_red;
		border: none;
		font-weight: bold;
		font-family: $font-family-basic;
        @include sp() {
            @include vw(32);

        }


	}

	&__linkBlock{
		padding-top: 1em;
	}
	&__link{
		// width: 100%;
		text-align: right;
		position: relative;
		line-height: 1;
		display: inline-block;
		@include fs(12);
		align-self:flex-start;
		text-align: left;
		&::before {
			content:"";
			display: inline-block;
			width: 4px;
			height: 4px;
			border-top: 2px solid #CF1721;
			border-right: 2px solid #CF1721;
			position: absolute;
			top: 50%;
			right: 0;
			margin-right: 0;
			transform: translate(0, -50%) rotate(45deg);
			transition: margin 300ms 0s ease;

		}

		a:hover &::before,
		&:hover::before {
			margin-right: -5px;
		}
	}
	&__linkTxt{
		line-height: 1;
		@include fs(12);
		letter-spacing: 0.7px;
		font-family: $font-family-Barlow;
		padding-right: 15px;
		font-weight: 600;
		@include sp() {
			@include vw(24);
		}

	}
    &__errMsg{
        color: #E30A15;
		@include fs(14);
        font-weight: bold;
 		@include sp() {
			@include vw(28);
		}

        div{
            font-weight: bold;
            @include fs(14);
            @include sp() {
                @include vw(28);
            }

        }
    }



}

