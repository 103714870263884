@charset "utf-8";


    // 全国の営業拠点
.p-topOfc{
    background: #242424;
    color: #fff;
    padding: 80px 20px;
    // min-width: 1080px;
    min-width: 1120px;
    @include sp() {
        min-width:auto;
        padding: 80px 0;
    }
    &__inner{
        max-width: 1080px;
        min-width: 1080px;
        margin: auto;
        position: relative;
        @include sp() {
            width: 680/750*100vw;
            min-width: auto;
        }
    }

    &List{
        padding-top: 32px;
        width: 630px;
        // height: 100%;
        display: flex;
        flex-wrap: wrap;

        // align-items:flex-start;
        // justify-content: flex-start;

        @include sp() {
            width: 100%;
            justify-content: space-between;

        }

        & > li{
            display: block;
            margin-right: 7px;
            margin-top: 7px;
            width: 202px;
            height: 58px;
            @include sp() {
                width: calc(50% - 3px);
                margin-right: 0;
                margin-top: 7px;
            }


        }
        a{
            height: 100%;
            display: block;
            background: #404040 ;
            color: #fff;
            font-weight: bold;
            line-height: 1;
            display: flex;
            align-items: center;
            justify-content:center;

            &:hover{
                background: #CF1721;
                opacity: 1;
            }



        }


    }
    &Map{
        display: block;
        @include pc() {
            width: 330px;
            height: 347px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
        @include sp() {
            margin: 80/750*100vw auto 0;
            width: 595/750*100vw;
        }
        &__img{
            display: block;
            @include pc() {
                width: 330px;
                height: 347px;
            }
            @include sp() {
                width: 100%;

            }

        }
        &__icon{
            @include sp() {
                display: none;
            }
            display: block;
            width: 2px;
            height: 2px;
            // background: #fff;
            // border: 1px solid #fff;
            // border-radius: 15px;
            position: absolute;
            transform: translate(-50%,-50%);

            &[data-mapid="tokyo"]{ top: 226px; left: 228px; }
            &[data-mapid="sendai"]{ top: 170px; left: 253px; }
            &[data-mapid="nagoya"]{ top: 239px; left: 190px; }
            &[data-mapid="osaka"]{ top: 243px; left: 150px; }
            &[data-mapid="hiroshima"]{ top: 251px; left: 103px; }
            &[data-mapid="fukuoka"]{ top: 278px; left: 48px; }
            &[data-mapid="center"]{ top: 223px; left: 251px; }
            &[data-mapid="pt"]{ top: 142px; left: 69px; }

            &.is-current{
                width: 30px;
                height: 30px;
                background:rgba($color: #CF1721, $alpha: .3) ;
                border-radius: 50%;

                &::before {
                    content:"";
                    display: block;
                    width: 20px;
                    height: 20px;
                    top: 5px;
                    right: 5px;
                    background:rgba($color: #CF1721, $alpha: .5) ;
                    position: absolute;
                    border-radius: 50%;
                }

            }

        }
        source,
        img {
            width: 100%;
        }
    }

}
