
.p-com{

    &FooterLink{

        display: flex;
        justify-content: space-between;
        padding: 100px 0;
        @include sp() {
            padding: 110/750*100vw 0;
            flex-wrap: wrap;

        }
        & > li{
			padding: 10/750*100vw 0;
            a{
                display: block;
            }

        }
         &__img{
                display: block;
                width: 203px;
                height: 160px;
                @include sp() {
                    width: 328/750*100vw;
                    height: 203/750*100vw;
                }
            }
        &__txt{
            @include fs(14);
            padding-top: 5px;
			display: block;
            @include sp() {
                @include vw(26);
				padding-top: 10/750*100vw;

            }
        }

    }
}
