.l-kv{
    width: 100%;
    display: block;
    height: 277px;
    position: relative;
    @include sp() {
        height: 338/750*100vw;
		width: 678/750*100vw;
		margin: auto;

    }
    &__ttl{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    &__txt{
		font-family: $font-family-Barlow;
		color: #CF1721;
        font-weight: 800;
		@include fs(38);
		display: block;
		line-height: 1;
        letter-spacing: 3.8px;
        @include sp() {
            @include vw(57);

        }

    }
    &__sub{
        padding-top: 10px;
		font-weight: bold;
		@include fs(18);
		display: block;
		line-height: 1;
        letter-spacing: 1.8px;

        color: #242424;
        @include sp() {
            @include vw(27);

        }
    }

}
