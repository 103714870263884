.p-prdDet{
		padding: 130px 20px 100px;
		width: 100%;
		max-width: 1166px;
		min-width: 1166px;
		display: block;
		margin: auto;
		@include sp() {
			max-width: 100%;
			min-width: auto;
			padding: 120/750*100vw 0 100/750*100vw;
            width: 680/750*100vw;

		}
	&__main{
		@include pc() {
			display: flex;
			justify-content: space-between;
		}
		// .p-prdDetSpec + & {
		// 	margin-top: 100px;
		// }

	}
	&__dataWrap{
		width: 320px;
		@include sp() {
			// width: 680/750*100vw;
			width: 100%;
			margin: auto;
			display: block;

		}

	}
	&__imgWrap{
		flex-grow: 1;
		text-align: center;
		@include sp() {
			// width: 680/750*100vw;
			width: 100%;
			margin: auto;
			display: block;
		}
		img{
			display: block;
			width:100%;
			height: auto;
			margin: auto;
			max-width: 400px;
			@include sp() {
				// max-width: 100%;
				max-width:446/750*100vw;
				margin:80/750*100vw auto;

			}

		}
	}
	&__dlWrap{
		width: 320px;
		padding-left: 100px;
		@include sp() {
			// width: 680/750*100vw;
			width: 100%;
			margin: auto;
			display: block;
			padding: 0;
		}

	}

	&__name{
		font-weight: bold;
		@include fs(28);
		@include sp() {
			@include vw(42);

		}
	}
	&__model{
		@include fs(14);
		@include sp() {
			@include vw(20);

		}
	}
	&__note{
		padding-top: 34px;
		@include fs(14);
		@include sp() {
			@include vw(23);
			padding-top: 40/750*100vw;


		}
		a{
			text-decoration: underline;
		}

	}



}
