.p-dist{
	&__subP{
		font-weight: bold;
		@include fs(16);
		@include sp() {
			@include vw(28);

		}

	}
	&__logo{
		img{
			display: inline-block;
			height: 50px;
			@include sp() {
				height: 80/750*100vw;
				width: auto;
			}

		}
	}


}
