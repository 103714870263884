.p-dlList{
	border-top: 1px solid rgba($color: #707070, $alpha: .3);



    &__item{
        padding: 16px 0;
        border-bottom: 1px solid rgba($color: #707070, $alpha: .3);
    }
    &__link{
        display: block;
        position: relative;

        &::before {
            content:"";
            display: inline-block;
            width: 4px;
            height: 4px;
            border-top: 2px solid #CF1721;
            border-right: 2px solid #CF1721;
            position: absolute;
            top: 50%;
            right: 0;
            margin-right: 1em;
            transform: translate(0, -50%) rotate(45deg);
        }

    }


    &__ttl{
        @include fs(14);
        padding-right: 1em;

        @include sp() {
            @include vw(28);
		}

    }
    &__date{
        padding-right: 1em;
        @include fs(14);

        @include sp() {
            width: auto;
            @include vw(28);
		}

    }


    &__inBox{
        display: flex;
        justify-content: space-between;
        @include sp() {
            flex-direction: column;
        }

    }

    &__model{
        @include pc() {
            @include fs(14);
            width: 30%;
            padding-right: 1em;
        }
        @include sp() {
            width: auto;
            @include vw(28);
		}

    }
    &__ver{
        @include pc() {
            @include fs(14);
            width: 20%;
            padding-right: 1em;
            display: flex;
            align-items:center;

        }
        @include sp() {
            width: auto;
            @include vw(28);
		}

    }

    &__date2{
        @include pc() {
            @include fs(14);
            width: 22%;
            padding-right: 1em;
            display: flex;
            align-items:center;

        }

        @include sp() {
            width: auto;
            @include vw(28);
		}

    }

    &__dl{
        display: flex;
        align-items:center;
        @include sp() {
            padding-top: 1em;
        }

    }

    // 詳細ページ用
    &__inData{
        flex-grow: 1;


    }
    &__soft{
        display: inline-block;
        @include fs(14);
        padding-right: 1em;

        @include sp() {
            @include vw(28);
        }
    }
    &__ver2{
        @include pc() {
            display: inline-block;
            @include fs(14);
        }
        @include sp() {
            display: block;
            @include vw(28);
        }
    }
    &__rele{
        @include pc() {
            display: inline-block;
            @include fs(14);
        }
        @include sp() {
            display: block;
            @include vw(28);
        }

    }
    &__etc{
        @include pc() {
            display: inline-block;
            @include fs(14);
            padding-right: 1em;
            &:empty{
                display: none;
            }

        }
        @include sp() {
            display: block;
            @include vw(28);
        }

    }



}
