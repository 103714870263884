.p-demoNav{
	display: flex;
	padding-top: 40px;
	justify-content: space-between;
	@include sp() {
		flex-wrap: wrap;

	}

	& > li{
		display: block;
		flex-grow: 1;
		flex-shrink: 1;
		line-height: 1;
		width: 100%;
		min-height: 43px;
		& + li{
			@include pc() {
				margin-left: 12px;
			}
			@include sp() {
				margin-top: 12px;

			}
		}
		@include sp() {
			width:calc(50% - 6px);
			min-height: 120/750*100vw;

			flex-grow: 0;
			&:first-child{
				width: 100%;
			}
		}


		a{
			padding:10px 2px;
			display: block;
			background: #F7F7F7;
			width: 100%;
			height: 100%;
			display: flex;
			justify-content:center;
			align-items:center;
			@include sp() {
				padding:10/750*100vw 10/750*100vw;

			}

		}
		&:hover a,
		&.is-current a{
			background: #CF1721;
			color: #fff;
		}
	}
	span{
		@include fs(12);
		line-height: 1.2;
		text-align: center;
		font-weight: bold;
		// word-break: keep-all;
		@include sp() {
			@include vw(28);

		}
	}


}
