.p-prditemList{
	padding: 20px 0;
	display: flex;
	flex-wrap: wrap;
	// & > li{
	&__item{
		// display: inline-block;
		width: 258px;
		height: 370px;
		background: #fff;
		margin: 16px 0 0 16px;
		position: relative;
		&:nth-child(4n+1){
			margin: 16px 0 0 0;
		}
		@include sp() {
			width: 330/750*100vw;
			margin: 18/750*100vw 0 0 18/750*100vw;
			height: auto;
			min-height: 474/750*100vw;
			&:nth-child(2n+1){
				margin: 18/750*100vw 0 0 0;
			}

		}
	}
	a{
		display: block;
		height: 100%;
		background: #fff;
		display: flex;
		flex-direction: column;
		padding: 54px 30px;
		&:hover{
			box-shadow: 0px 0px 30px rgba($color: #000000, $alpha: .2);
		}
		@include sp() {
			padding: 54/750*100vw 30/750*100vw;

		}
	}
	&__imgWrap{
		flex-grow: 1;

		img{
			max-width: 143px;
			max-height: 112px;
			width: auto;
			height: auto;
			margin: auto;
			display: block;
			@include sp() {
				max-width: 180/750*100vw;
				max-height: 140/750*100vw;

			}
		}
	}
	&__name{
		text-align: center;
		font-weight: bold;
		min-height: 4em;
		padding-bottom: 1em;
		@include fs(14);
		@include sp() {
			@include vw(24);

		}


	}
	&__model{
		text-align: center;
		min-height: 2em;
		@include fs(12);
		@include sp() {
			@include vw(22);
		}
	}
	// 廃版
	&__discon{
		position: absolute;
		top: 10px;
		left: 10px;
		padding:6px 10px;
		border: 1px solid #747474;
		color: #747474;
		line-height: 1;
		@include fs(14);
		@include sp() {
			@include vw(24);

		}

	}
}
