.m-linkB{
    max-width: 100%;
    text-align: right;
    position: relative;
    line-height: 1;
    @include fs(12);
	padding: 0;

	background: transparent;
	border:none;
	&:not(.is-disable){
		cursor: pointer;
		&:hover{
			opacity: .7;
		}
	}

    @include sp() {
        @include vw(28);
        // width: 150/750*100vw;
        flex-shrink: 0;
        height: 1em ;
        align-self:flex-end;
		text-align: left;

    }
    &::before {
        content:"";
        display: inline-block;
        width: 14px;
        height: 14px;
		// border: 1px solid #000;
        position: absolute;
        top: 50%;
        right: 0;
        margin-right: 0;
        transform: translate(0, -50%);
		background: #ccc;
		background:transparent url(#{$imgPath}common/icon_dl.svg) no-repeat bottom center;
		background-size: contain;

    }
	&.is-disable{
		&::before {
			display: none;
		}

	}

    // a:hover &::before,
    // &:hover::before {
    //     margin-right: -5px;
    // }


    &__linkTxt{
        line-height: 1;
        @include fs(12);
        letter-spacing: 0.7px;
        font-family: $font-family-Barlow;
        padding-right: 20px;
        font-weight: 600;
        @include sp() {
            @include vw(28);
			text-align: left;
        }
		.is-disable &{
			color: #ccc;

		}
    }

}
