.l-headerBtnSP{
		@include pc() {
			display: none;
		}

		display: block;
		width: 24px;
		height: 18px;
		align-self: center;
		cursor: pointer;
		position: relative;
		margin-left: 20px;
		margin-right: 20px;
		z-index: 10;

		&__icon{
			width: 100%;
			height: 2px;
			background: #000;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
		&::after,
		&::before {
			content:"";
			display: inline-block;
			background: #000;
			width: 100%;
			height: 2px;
			position: absolute;
			right: 0;
			transition: all 300ms 0s ease;
		}
		&::after{
			top:0;
		}
		&::before {
			bottom:0;
		}

		&.is-open{
			.l-headerBtnSP__icon{
				display: none;
			}
			&::after,
			&::before {
				background: #fff;
				top:9px;
			}
			&::after{
				transform: rotate(-45deg);
			}
			&::before {
				transform: rotate(45deg);
			}

		}





	}




// .l-headerSP{
// 	@include pc() {
// 		display: none;
// 	}
// 	&__body{
// 		display: none;
// 		width: 100%;
// 		height: 120vh;
// 		position: absolute;
// 		top: 0;
// 		right: 0;
// 		background: #ccc;
// 		color: #000;
// 		padding: 10px;
// 		img{
// 			max-width: 100px;
// 		}

// 	}

// }
