
// ご要望に合わせたOEMのご提供には下記の3つの方法があります。
.p-comOemSubttl{
	text-align: center;
	font-weight: bold;
	padding: 0 ;
	position: relative;
	&::before {
		content:"";
		display: inline-block;
		width: 100%;
		height: 2px;
		position: absolute;
		background: #CF1721;
		top: 50%;
		left: 0;
		// z-index: 0;
		@include sp() {
			display: none;

		}
	}

	&__txt{
		@include fs(22);
		line-height: 1;
		background: #EDEFF2;
		position: relative;
		z-index: 1;
		display: inline-block;
		padding:0 1em;
		letter-spacing: 0.1em;
		@include sp() {
			@include vw(42);
			line-height: 1.4;
			padding: 0;

		}


	}

	span{
		color: #CF1721;
		@include fs(22);
		line-height: 1;
		@include sp() {
			@include vw(42);
			line-height: 1.4;

		}

	}
	&__box{
		background: #fff;
	}
}
